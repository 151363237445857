import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import PageLoading from "../../../components/PageLoading";
import { useGetAllUsersTransactionsQuery } from "../../../Slices/transactionApsSlice";
import { BASE_URL } from "../../../utils";
import FormattedDate from "../../../components/FormattedDate";
import Search from "../../../components/Search";

function Bills() {
  const [transaction, setTransaction] = useState([]);
  const [transactionModal, setTransactionModal] = useState(false);
  const [singleTrans, setSingleTrans] = useState([]);

  const { data, isLoading, error } = useGetAllUsersTransactionsQuery();

  useEffect(() => {
    if (data) {
      setTransaction(data.expensePayment);
    }
  }, [data]);

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;

  const getHandler = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}admin/getSingleExpnseTransaction/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );

      // console.log(res.data.expenseTransaction)
      const response = res.data.expenseTransaction;
      setSingleTrans(response);
      setTransactionModal(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };
  return (
    <div className="px-10">
      <div className="flex justify-between items-center  gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            <Link to="/dashboard/index">Home</Link>
            <span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            <Link to="/dashboard/merchant">Merchant Financial Base</Link>
            <span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Bills and Utility
          </h2>
          <Link
            to="/dashboard/merchant"
            className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back
          </Link>
        </div>

        <Search />
      </div>
      <div className="flex justify-between items-center  gap-x-2  ">
        <div>
          <h2 className="text-[#0C0C0C]   text-lg font-extrabold ">
            Bills and Utility
          </h2>
        </div>

        <div className="w-[10rem] ">
          <div class="relative">
            <select class="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-sm w-full focus:outline-none px-5 text-sm">
              <option selected>Select</option>
              <option value="male">Monthly</option>
              <option value="female">Yearly</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* Table+++++++++++++++++++++++++++++++ */}

      <div class="flex flex-col my-8">
        <div class="overflow-x-auto rounded-lg h-[30rem] scrollbar-hide">
          <div class="align-middle inline-block min-w-full">
            <div class="shadow overflow-hidden sm:rounded-lg">
              {isLoading ? (
                <div className="pb-5">
                  <PageLoading />
                </div>
              ) : error ? (
                <div className="pt-5">
                  <Loading />
                </div>
              ) : (
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        ACCOUNT NAME
                      </th>
                      <th
                        scope="col"
                        class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        DATE & TIME
                      </th>
                      <th
                        scope="col"
                        class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        AMOUNT
                      </th>
                      <th
                        scope="col"
                        class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        STATUS
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        TYPE
                      </th>
                      <th
                        scope="col"
                        className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        DESCRIPTION
                      </th>
                    </tr>
                  </thead>

                  <tbody class="bg-white">
                    {transaction.map((item, i) => (
                      <tr
                        key={item.id}
                        className={i % 2 === 0 ? "" : "bg-gray-50"}
                      >
                        <td
                          onClick={() => getHandler(item.id)}
                          class="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                              fill="#141B34"
                            />
                            <path
                              d="M7.33337 6.41667H14.6667"
                              stroke="#141B34"
                              stroke-width="1.5"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7.33337 10.0833H11"
                              stroke="#141B34"
                              stroke-width="1.5"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <span class="font-semibold">
                            {item.user.full_name}
                          </span>
                        </td>
                        <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                          <FormattedDate date={item?.created_at} />
                        </td>
                        <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                          ₦ {item.amount.toLocaleString()}
                        </td>
                        <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                          {item.status === "completed" && (
                            <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                              <span
                                aria-hidden
                                class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                              ></span>
                              <span class="relative text-xs font-normal">
                                Completed
                              </span>
                            </span>
                          )}

                          {item.status === "cancelled" && (
                            <span class="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                              <span
                                aria-hidden
                                class="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                              ></span>
                              <span class="relative text-xs font-normal">
                                Cancelled
                              </span>
                            </span>
                          )}

                          {item.status === "pending" && (
                            <span class="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                              <span
                                aria-hidden
                                class="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                              ></span>
                              <span class="relative text-xs font-normal">
                                In progress
                              </span>
                            </span>
                          )}
                        </td>
                        <td className="p-4 whitespace-nowrap text-center text-sm font-semibold text-gray-900">
                          {item.tx_type}
                        </td>
                        <td className="p-4 whitespace-nowrap text-left text-sm font-semibold text-gray-900">
                          {item.description}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>

      {transactionModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setTransactionModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Back to users
                </button>
              </div>

              <div className="py-2">
                {singleTrans.status === "completed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Completed
                    </span>
                  </span>
                )}

                {singleTrans.status === "failed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">Failed</span>
                  </span>
                )}

                {singleTrans.status === "pending" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      In progress
                    </span>
                  </span>
                )}
              </div>
              {/* <h3 className="text-[#000] text-lg font-semibold py-3">
																		Confirm Account Ban
																	</h3> */}

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C] text-left  text-sm font-normal ">
                  Transaction Reference
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] p-4  text-sm font-normal  rounded-lg mt-1 text-left">
                  {singleTrans.reference}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-normal ">
                  Transaction Date & Time
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] p-4  text-sm font-normal  rounded-lg mt-1 text-left">
                  <FormattedDate date={singleTrans?.created_at} />
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-normal ">
                  Transaction type
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] p-4  text-sm font-normal  rounded-lg mt-1 text-left">
                  {singleTrans.tx_type}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-normal ">
                  Amount
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] p-4  text-sm font-normal  rounded-lg mt-1 text-left">
                  {singleTrans.amount}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-normal ">
                  Description
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] p-4  text-sm font-normal  rounded-lg mt-1 text-left">
                  {singleTrans.description}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-normal ">
                  Channel
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] p-4  text-sm font-normal  rounded-lg mt-1 text-left">
                  {singleTrans.channel}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Bills;
