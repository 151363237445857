import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setCredentials } from '../Slices/authSlice';
import { useForm } from 'react-hook-form';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../Slices/userApsSlice';

function Login() {
	const [tab, setTab] = useState(1);
	const [passwordType, setPasswordType] = useState('password');
	const [email, setEmail] = useState('');

	const navigate = useNavigate();

	const [login, { isLoading }] = useLoginMutation();
	const { register, handleSubmit } = useForm();

	const dispatch = useDispatch();

	const loginHandler = async (data) => {
		try {
			const res = await login(data).unwrap();
			dispatch(setCredentials({ ...res }));
			navigate('/dashboard/index');
		} catch (err) {
			toast.error(err.error || err?.data?.error);
		}
	};

	const { userInfo } = useSelector((state) => state.auth);
	useEffect(() => {
		if (userInfo) {
			navigate('/dashboard/index');
		}
	}, [navigate, userInfo]);

	return (
    <>
      <header className="bg-white z-[100] shadow-md font-Inter sticky top-0">
        <nav
          className="mx-auto hidden lg:flex max-w-7xl items-center justify-between   px-2   "
          aria-label="Global"
        >
          <Link to="/" className=" m-5">
            <img className="h-12 w-auto" src="../logo.png" alt="" />
          </Link>

          <div className="flex flex-1 font-Inter justify-end gap-4 ">
            <div className="flex gap-2 items-center">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTab(1);
                }}
                class="w-[6rem]  p-1.5 text-[#284C84] text-sm font-normal transition duration-200 hover:text-[#757E7E] mx-auto "
              >
                Log in
              </button>

              <button
                onClick={(e) => {
                  e.preventDefault();
                  setTab(2);
                }}
                class="w-[10rem] bg-[#284C84] rounded-lg  py-4 px-1.5 text-white  transition duration-200 hover:bg-[#757E7E] mx-auto "
              >
                Forgot Password
              </button>
            </div>
          </div>
        </nav>
      </header>
      <div
        class=" w-full bg-center bg-cover h-screen"
        style={{ "background-image": "url(bg.png)" }}
      >
        <div class="lg:flex items-center justify-between lg:px-20 bg-[#000] -mt-20 bg-opacity-80 h-full">
          <div>
            <h2 class="text-2xl font-bold text-white sm:text-5xl w-[13rem]">
              Admin Dashboard
            </h2>

            <p class="max-w-xl mt-3 text-gray-300 w-[26rem]">
              BillTopNg makes it possible to bring financial activities to your
              doorstep with our users and agents’ network solely to make such
              transactions fast, secure and convenient.
            </p>
          </div>
          <div className=" justify-center sm:pb-[8.5rem] sm:py-[3.5rem]    px-4 py-6   ">
            <div className="max-w-sm p-6  bg-[#fff] rounded-sm">
              <div class={tab === 1 ? "block" : "hidden"}>
                <div class="text-center">
                  <div class="flex justify-center mx-auto">
                    <svg
                      className="w-[7rem] h-[6rem]"
                      width="134"
                      height="134"
                      viewBox="0 0 134 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M67 11.1667C36.18 11.1667 11.1667 36.18 11.1667 67C11.1667 97.82 36.18 122.833 67 122.833C97.82 122.833 122.833 97.82 122.833 67C122.833 36.18 97.82 11.1667 67 11.1667ZM67 33.5C77.7758 33.5 86.5417 42.2658 86.5417 53.0417C86.5417 63.8175 77.7758 72.5833 67 72.5833C56.2242 72.5833 47.4583 63.8175 47.4583 53.0417C47.4583 42.2658 56.2242 33.5 67 33.5ZM67 111.667C55.6658 111.667 42.2658 107.088 32.7183 95.5867C42.4981 87.9136 54.5694 83.7433 67 83.7433C79.4306 83.7433 91.5019 87.9136 101.282 95.5867C91.7342 107.088 78.3342 111.667 67 111.667Z"
                        fill="#E7E7E7"
                      />
                    </svg>
                  </div>

                  <h1 className=" text-3xl font-bold text-center text-[#000] ">
                    Welcome Back!
                  </h1>

                  <p className=" px-12 text-sm text-center text-gray-500 ">
                    Enter your Log in Details to continue
                  </p>
                </div>

                <div class="mt-4">
                  <form onSubmit={handleSubmit(loginHandler)}>
                    <div>
                      <label
                        for="username"
                        class="block text-sm text-gray-800 font-semibold pb-2"
                      >
                        Email *
                      </label>
                      <input
                        type="email"
                        name="email"
                        required
                        {...register("email")}
                        placeholder="Email Address"
                        class="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>

                    <div className="mt-2">
                      <label
                        for="username"
                        class="block text-sm text-gray-800 font-semibold pb-2"
                      >
                        Password *
                      </label>
                      <div class="flex ">
                        <input
                          {...register("password")}
                          required
                          type={passwordType}
                          name="password"
                          id="password"
                          placeholder="Your Password"
                          class="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                          <button
                            type="button"
                            onClick={() => {
                              if (passwordType === "password") {
                                setPasswordType("text");
                                return;
                              }
                              setPasswordType("password");
                            }}
                            className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                          >
                            {passwordType === "password" ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_5982_274025)">
                                  <path
                                    d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                                    stroke="#757E7E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M1 1L23 23"
                                    stroke="#757E7E"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5982_274025">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            ) : (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                                  fill="#757E7E"
                                />
                              </svg>
                            )}
                          </button>
                        </span>
                      </div>
                    </div>

                    <div class="mt-3">
                      <button
                        type="submit"
                        disabled={isLoading}
                        class="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#284C84] rounded-sm hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      >
                        {isLoading ? <Spinner /> : "Log in"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div class={tab === 2 ? "block" : "hidden"}>
                <div class="text-center">
                  <div class="flex justify-center mx-auto">
                    <svg
                      className="w-[7rem] h-[6rem]"
                      width="134"
                      height="134"
                      viewBox="0 0 134 134"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M67 11.1667C36.18 11.1667 11.1667 36.18 11.1667 67C11.1667 97.82 36.18 122.833 67 122.833C97.82 122.833 122.833 97.82 122.833 67C122.833 36.18 97.82 11.1667 67 11.1667ZM67 33.5C77.7758 33.5 86.5417 42.2658 86.5417 53.0417C86.5417 63.8175 77.7758 72.5833 67 72.5833C56.2242 72.5833 47.4583 63.8175 47.4583 53.0417C47.4583 42.2658 56.2242 33.5 67 33.5ZM67 111.667C55.6658 111.667 42.2658 107.088 32.7183 95.5867C42.4981 87.9136 54.5694 83.7433 67 83.7433C79.4306 83.7433 91.5019 87.9136 101.282 95.5867C91.7342 107.088 78.3342 111.667 67 111.667Z"
                        fill="#E7E7E7"
                      />
                    </svg>
                  </div>

                  <h1 className=" text-3xl font-bold text-center text-[#000] ">
                    Forgot Password
                  </h1>

                  <p className=" px-12 text-sm text-center text-gray-500 ">
                    Enter your Email to continue
                  </p>
                </div>

                <div class="my-10">
                  <form>
                    <div>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onInput={(e) => setEmail(e.currentTarget.value)}
                        id="email"
                        placeholder="Email Address"
                        class="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg focus:border-blue-400  focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                      />
                    </div>

                    <div class="mt-2">
                      <button class="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#284C84] rounded-sm hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                        Reset
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
