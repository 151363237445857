import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/layouts/DashboardLayout";
import Analysis from "./pages/DashboardScreen/Analysis";
import Dashboard from "./pages/DashboardScreen/Index";
import Transactions from "./pages/DashboardScreen/Transactions";
import Users from "./pages/DashboardScreen/Users/Index";
import BannedAccounts from "./pages/DashboardScreen/Users/BannedAccounts";
import UserDetails from "./pages/DashboardScreen/Users/UserDetails";
import Login from "./pages/Login";
import Support from "./pages/DashboardScreen/Support/Index";
import Report from "./pages/DashboardScreen/Support/Report";
import Feedback from "./pages/DashboardScreen/Support/Feedback";
import Message from "./pages/DashboardScreen/Support/Message";
import Settings from "./pages/DashboardScreen/Settings/Index";
import ManageAccounts from "./pages/DashboardScreen/Settings/ManageAccounts";
import Notification from "./pages/DashboardScreen/Settings/Notification";
import System from "./pages/DashboardScreen/Settings/System";
import Airtime from "./pages/DashboardScreen/Merchant/Airtime";
import Merchant from "./pages/DashboardScreen/Merchant/Index";
import Betting from "./pages/DashboardScreen/Merchant/Betting";
import Bills from "./pages/DashboardScreen/Merchant/Bills";
import ESim from "./pages/DashboardScreen/Merchant/ESim";
import GiftCards from "./pages/DashboardScreen/Merchant/GiftCards";
import Events from "./pages/DashboardScreen/Merchant/Events";
import AuthRoute from "./components/AuthRoute";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Finpay from "./pages/DashboardScreen/Merchant/PayUser";
import PayUser from "./pages/DashboardScreen/ManagementInfo/PayUser";
import ManagementInfo from "./pages/DashboardScreen/ManagementInfo/Index";
import InfoAirtime from "./pages/DashboardScreen/ManagementInfo/Airtime";
import InfoBetting from "./pages/DashboardScreen/ManagementInfo/Betting";
import InfoBills from "./pages/DashboardScreen/ManagementInfo/Bills";
import InfoESim from "./pages/DashboardScreen/ManagementInfo/ESim";
import InfoGiftCards from "./pages/DashboardScreen/ManagementInfo/GiftCards";
import InfoEvents from "./pages/DashboardScreen/ManagementInfo/Events";
import Crypto from "./pages/DashboardScreen/ManagementInfo/Crypto";

function App() {
  return (
    <div className="font-SF Pro Display">
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="" element={<AuthRoute />}>
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route path="index" element={<Dashboard />} />
              <Route path="users" element={<Users />} />
              <Route path="users/banned" element={<BannedAccounts />} />
              <Route path="users/:id/Details" element={<UserDetails />} />
              <Route path="analysis" element={<Analysis />} />
              <Route path="transactions" element={<Transactions />} />{" "}
              <Route path="support" element={<Support />} />
              <Route path="support/reports" element={<Report />} />
              <Route path="support/feedbacks" element={<Feedback />} />
              <Route path="support/messages" element={<Message />} />
              <Route path="settings" element={<Settings />} />
              <Route path="settings/accounts" element={<ManageAccounts />} />
              <Route path="settings/notifications" element={<Notification />} />
              <Route
                path="settings/system_configuration"
                element={<System />}
              />
              <Route path="merchant" element={<Merchant />} />
              <Route path="merchant/airtime" element={<Airtime />} />
              <Route path="merchant/betting" element={<Betting />} />
              <Route path="merchant/payuser" element={<Finpay />} />
              <Route path="merchant/bills" element={<Bills />} />
              <Route path="merchant/esim" element={<ESim />} />
              <Route path="merchant/giftcards" element={<GiftCards />} />
              <Route path="merchant/events" element={<Events />} />
              {/* ******************* Management Info *******************/}
              <Route path="management_info" element={<ManagementInfo />} />
              <Route path="management_info/airtime" element={<InfoAirtime />} />
              <Route path="management_info/betting" element={<InfoBetting />} />
              <Route path="management_info/payuser" element={<PayUser />} />
              <Route path="management_info/bills" element={<InfoBills />} />
              <Route path="management_info/esim" element={<InfoESim />} />
              <Route path="management_info/crypto" element={<Crypto />} />
              <Route
                path="management_info/giftcards"
                element={<InfoGiftCards />}
              />
              <Route path="management_info/events" element={<InfoEvents />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
