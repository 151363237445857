import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { logout } from "../../Slices/authSlice";
import useIdleTimeout from "../useIdleTimeout";

function DashboardLayout() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  // Logout Session
  const dispatch = useDispatch();
  const logoutHandler = async () => {
    try {
      dispatch(logout());
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const handleIdle = () => {
    setOpenModal(true);
  };
  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 100 });

  const stay = () => {
    setOpenModal(false);
    idleTimer.reset();
  };
  // Function to handle actions on timeout (e.g., logout, redirect)
  const handleOnTimeout = () => {
    setOpenModal(false);
    dispatch(logout());
    navigate("/");
  };
  return (
    <div className="flex font-Inter">
      {openModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold">
                  Session TimeOut
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Your session is about to expire. You'll be automatically signed
                out.
              </p>
              <p className="text-left pb-5 text-sm text-[#141414]/70">
                Do you want to stay signed in?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={() => {
                    handleOnTimeout();
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Log out now
                </button>
                <button
                  onClick={() => {
                    stay();
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#545252]  px-3 py-4 rounded-lg "
                >
                  Stay signed in
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`sidebar  hidden lg:block overflow-hidden   shadow-lg bg-gradient-to-b from-[#284C84]  to-[#062F70] min-h-screen );
					`}
      >
        <div className="flex flex-col justify-between px-2.5 pb-6">
          <div className="min-w-max  ">
            <div class="flex items-center justify-between flex-row px-2 py-3 ">
              <div className="flex items-center">
                <svg
                  className="w-20"
                  width="134"
                  height="134"
                  viewBox="0 0 134 134"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M67 11.1667C36.18 11.1667 11.1667 36.18 11.1667 67C11.1667 97.82 36.18 122.833 67 122.833C97.82 122.833 122.833 97.82 122.833 67C122.833 36.18 97.82 11.1667 67 11.1667ZM67 33.5C77.7758 33.5 86.5417 42.2658 86.5417 53.0417C86.5417 63.8175 77.7758 72.5833 67 72.5833C56.2242 72.5833 47.4583 63.8175 47.4583 53.0417C47.4583 42.2658 56.2242 33.5 67 33.5ZM67 111.667C55.6658 111.667 42.2658 107.088 32.7183 95.5867C42.4981 87.9136 54.5694 83.7433 67 83.7433C79.4306 83.7433 91.5019 87.9136 101.282 95.5867C91.7342 107.088 78.3342 111.667 67 111.667Z"
                    fill="#E7E7E7"
                  />
                </svg>
                <div class="flex flex-col ">
                  <div class="text-gray-100 text-sm font-semibold">
                    {userInfo.admin.name}
                  </div>
                  <div class="flex w-full mt-1 text-gray-400 font-thin text-xs">
                    {userInfo.admin.role}
                  </div>
                </div>
              </div>
              {/* <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_252_2522)">
                  <path
                    d="M11.953 22.8109C12.3713 22.8013 12.7728 22.644 13.0863 22.367C13.3999 22.0899 13.6053 21.7108 13.6663 21.2969H10.1733C10.2361 21.7221 10.4511 22.11 10.7785 22.3885C11.1059 22.667 11.5233 22.8171 11.953 22.8109Z"
                    fill="white"
                  />
                  <path
                    d="M21.5887 18.5277C20.9484 17.9569 20.3877 17.3024 19.9219 16.582C19.4134 15.5876 19.1086 14.5017 19.0255 13.3879V10.1074C19.0228 9.70895 18.9872 9.31138 18.9192 8.91875C18.3965 8.81427 17.8942 8.6259 17.4317 8.36094C17.608 8.92861 17.6975 9.51965 17.6973 10.1141V13.3945C17.7787 14.7532 18.1525 16.0783 18.793 17.2793C19.2515 18.0058 19.7955 18.6746 20.4133 19.2715H3.4067C4.02455 18.6746 4.56854 18.0058 5.02701 17.2793C5.66758 16.0783 6.04132 14.7532 6.12272 13.3945V10.1074C6.11922 9.33889 6.26736 8.57722 6.55864 7.86603C6.84992 7.15483 7.27862 6.50807 7.8202 5.96278C8.36179 5.4175 9.00561 4.9844 9.71481 4.68828C10.424 4.39217 11.1847 4.23885 11.9532 4.23711C13.078 4.23801 14.1775 4.57064 15.1141 5.19336C15.0109 4.81417 14.9529 4.42408 14.9415 4.03125V3.61289C14.2481 3.2718 13.5032 3.04743 12.7368 2.94883V2.11211C12.7368 1.87699 12.6434 1.6515 12.4771 1.48524C12.3109 1.31899 12.0854 1.22559 11.8503 1.22559C11.6151 1.22559 11.3896 1.31899 11.2234 1.48524C11.0571 1.6515 10.9637 1.87699 10.9637 2.11211V2.98203C9.24761 3.22412 7.677 4.07889 6.54189 5.38855C5.40677 6.69821 4.78379 8.37431 4.78795 10.1074V13.3879C4.70478 14.5017 4.39999 15.5876 3.89147 16.582C3.43385 17.3008 2.88222 17.9551 2.25123 18.5277C2.1804 18.59 2.12363 18.6666 2.0847 18.7524C2.04577 18.8383 2.02557 18.9315 2.02545 19.0258V19.9289C2.02545 20.105 2.09542 20.2739 2.21995 20.3985C2.34449 20.523 2.51339 20.593 2.68951 20.593H21.1505C21.3266 20.593 21.4955 20.523 21.62 20.3985C21.7446 20.2739 21.8145 20.105 21.8145 19.9289V19.0258C21.8144 18.9315 21.7942 18.8383 21.7553 18.7524C21.7163 18.6666 21.6596 18.59 21.5887 18.5277Z"
                    fill="white"
                  />
                  <path
                    d="M19.9219 7.35156C21.7556 7.35156 23.2422 5.86501 23.2422 4.03125C23.2422 2.19749 21.7556 0.710938 19.9219 0.710938C18.0881 0.710938 16.6016 2.19749 16.6016 4.03125C16.6016 5.86501 18.0881 7.35156 19.9219 7.35156Z"
                    fill="#ED5846"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_252_2522">
                    <rect
                      width="23.9062"
                      height="23.9062"
                      fill="white"
                      transform="translate(0 0.046875)"
                    />
                  </clipPath>
                </defs>
              </svg> */}
            </div>
          </div>
          <div>
            <ul className=" px-2 tracking-wide  overflow-y-auto scrollbar-hide">
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/index"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 4.04167C2 3.66594 2.15804 3.30561 2.43934 3.03993C2.72064 2.77426 3.10218 2.625 3.5 2.625H8V15.375H3.5C3.10218 15.375 2.72064 15.2257 2.43934 14.9601C2.15804 14.6944 2 14.3341 2 13.9583V4.04167ZM11 2.625H15.5C15.8978 2.625 16.2794 2.77426 16.5607 3.03993C16.842 3.30561 17 3.66594 17 4.04167V7.58333H11V2.625ZM11 10.4167H17V13.9583C17 14.3341 16.842 14.6944 16.5607 14.9601C16.2794 15.2257 15.8978 15.375 15.5 15.375H11V10.4167Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className=" text-sm font-medium">Dashboard</span>
                </NavLink>
              </li>
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/users"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.51793 7.25C8.46613 7.25 8.94023 7.25 9.38022 7.01629C9.64344 6.87648 9.97364 6.57023 10.1328 6.31826C10.3989 5.89708 10.4256 5.54324 10.4789 4.83557C10.5082 4.44763 10.5071 4.06594 10.4751 3.67106C10.4175 2.95801 10.3887 2.60148 10.1289 2.18726C9.96526 1.92644 9.6305 1.61765 9.35734 1.4756C8.92354 1.25 8.45334 1.25 7.51294 1.25V1.25C6.53627 1.25 6.04794 1.25 5.6082 1.48338C5.32774 1.63223 4.99696 1.9454 4.83302 2.21731C4.57596 2.64365 4.55506 3.02571 4.51326 3.78982C4.49635 4.09898 4.49567 4.40116 4.51092 4.70586C4.54931 5.47283 4.56851 5.85631 4.83198 6.28942C4.9922 6.55281 5.3169 6.86166 5.58797 7.00851C6.03371 7.25 6.52845 7.25 7.51793 7.25V7.25Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                    <path
                      d="M14 16.75L14.083 15.8652C14.2267 14.332 14.2986 13.5654 13.7254 12.5004C13.5456 12.1664 13.0292 11.5401 12.7356 11.2999C11.7994 10.5342 11.2598 10.481 10.1805 10.3745C9.40489 10.298 8.51415 10.25 7.5 10.25C6.48584 10.25 5.59511 10.298 4.81945 10.3745C3.74021 10.481 3.20059 10.5342 2.26443 11.2999C1.97077 11.5401 1.45441 12.1664 1.27462 12.5004C0.701444 13.5654 0.773312 14.332 0.917049 15.8652L1 16.75"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                  <span className="-mr-1 text-sm font-medium">Users</span>
                </NavLink>
              </li>
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/analysis "
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.32139 16.8929L15.6785 16.8929C16.3492 16.8929 16.8928 16.3492 16.8928 15.6786L16.8928 2.32144C16.8928 1.65081 16.3492 1.10715 15.6785 1.10715L2.32139 1.10715C1.65076 1.10715 1.10711 1.65081 1.10711 2.32144L1.10711 15.6786C1.10711 16.3492 1.65076 16.8929 2.32139 16.8929Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.14282 4.14287H6.57139M4.14282 7.17858H9.60711M14.4643 7.17858L10.8214 13.25L6.57139 10.8214L4.14282 14.4643"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className="-mr-1 text-sm font-medium">
                    System Analysis
                  </span>
                </NavLink>
              </li>
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/management_info"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.3153 17.3354C21.6317 13.3866 19.5033 10.4461 17.6531 8.719C17.1147 8.21642 16.8455 7.96513 16.2508 7.73257C15.6561 7.5 15.145 7.5 14.1227 7.5H11.8773C10.855 7.5 10.3439 7.5 9.74919 7.73257C9.15452 7.96513 8.88532 8.21642 8.34691 8.719C6.49669 10.4461 4.36834 13.3866 3.68465 17.3354C3.17597 20.2734 5.99924 22.5 9.1545 22.5H16.8455C20.0007 22.5 22.8241 20.2734 22.3153 17.3354Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.0593 4.94287C7.8444 4.64258 7.53291 4.23499 8.17636 4.14205C8.83774 4.04651 9.5245 4.48114 10.1967 4.47221C10.8049 4.46413 11.1147 4.20519 11.4471 3.83548C11.7971 3.44617 12.3391 2.5 13.0003 2.5C13.6616 2.5 14.2036 3.44617 14.5536 3.83548C14.886 4.20519 15.1957 4.46413 15.8039 4.47221C16.4762 4.48114 17.1629 4.04651 17.8243 4.14205C18.4677 4.23499 18.1563 4.64258 17.9414 4.94287L16.9696 6.30064C16.554 6.88146 16.3462 7.17187 15.9112 7.33594C15.4763 7.5 14.9142 7.5 13.7901 7.5H12.2105C11.0865 7.5 10.5244 7.5 10.0895 7.33594C9.65451 7.17187 9.44668 6.88146 9.031 6.30064L8.0593 4.94287Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6949 13.4186C14.4697 12.6205 13.3234 11.9003 11.9475 12.4391C10.5715 12.9778 10.353 14.7113 12.4343 14.8955C13.3749 14.9787 13.9882 14.7989 14.5497 15.3076C15.1112 15.8162 15.2155 17.2308 13.7801 17.612C12.3447 17.9932 10.9233 17.3976 10.7686 16.5517M12.8355 11.4927V12.2531M12.8355 17.7293V18.4927"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span className="-mr-1 text-sm font-medium">
                    Management Info System
                  </span>
                </NavLink>
              </li>
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/transactions"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 14V15V17M7.5 17H11.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M14 1C15.4045 1 16.1067 1 16.6111 1.33706C16.8295 1.48298 17.017 1.67048 17.1629 1.88886C17.5 2.39331 17.5 3.09554 17.5 4.5L17.5 10.5C17.5 11.9045 17.5 12.6067 17.1629 13.1111C17.017 13.3295 16.8295 13.517 16.6111 13.6629C16.1067 14 15.4045 14 14 14L5 14C3.59554 14 2.89331 14 2.38886 13.6629C2.17048 13.517 1.98298 13.3295 1.83706 13.1111C1.5 12.6067 1.5 11.9045 1.5 10.5L1.5 4.5C1.5 3.09554 1.5 2.39331 1.83706 1.88886C1.98298 1.67048 2.17048 1.48298 2.38886 1.33706C2.89331 0.999999 3.59554 0.999999 5 0.999999L14 1Z"
                      stroke="white"
                      stroke-width="1.5"
                    />
                    <path
                      d="M8.5 6L6.5 9"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12.7412 6L10.7412 9"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                  <span className="-mr-1 text-sm font-medium">
                    Transactions
                  </span>
                </NavLink>
              </li>
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/merchant"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_43_297)">
                      <path
                        d="M18.0207 7.96429H1.97931C1.21931 7.96429 0.893599 7.13643 1.49074 6.74286L9.51146 1.49071C9.65954 1.4036 9.82822 1.35767 10 1.35767C10.1718 1.35767 10.3405 1.4036 10.4886 1.49071L18.5093 6.74286C19.1065 7.13643 18.7807 7.96429 18.0207 7.96429Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.1429 15.4286H1.85716C1.4824 15.4286 1.17859 15.7324 1.17859 16.1071V18.1429C1.17859 18.5176 1.4824 18.8214 1.85716 18.8214H18.1429C18.5176 18.8214 18.8214 18.5176 18.8214 18.1429V16.1071C18.8214 15.7324 18.5176 15.4286 18.1429 15.4286Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.21429 7.96429V15.4286M6.60715 7.96429V15.4286M10 7.96429V15.4286M13.3929 7.96429V15.4286M16.7857 7.96429V15.4286"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_43_297">
                        <rect
                          width="19"
                          height="19"
                          fill="white"
                          transform="translate(0.5 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="-mr-1 text-sm font-medium">
                    Merchant Financial Base
                  </span>
                </NavLink>
              </li>
              {userInfo.admin.role === "superAdmin" && (
                <li className="min-w-max ">
                  <NavLink
                    to="/dashboard/settings"
                    className={({ isActive }) =>
                      isActive
                        ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                        : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                    }
                  >
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.82233 2.75427C7.02487 0.456625 10.276 0.396697 10.398 2.68836C10.4675 3.99415 11.7898 4.76859 12.9394 4.17678C14.9568 3.13815 16.5312 6.02423 14.6709 7.35098C13.611 8.10697 13.5829 9.70513 14.6171 10.4225C16.432 11.6815 14.7552 14.6275 12.773 13.6626C11.6434 13.1128 10.2931 13.9365 10.1777 15.2457C9.97513 17.5434 6.72404 17.6033 6.60202 15.3116C6.53249 14.0059 5.21017 13.2314 4.06062 13.8232C2.04318 14.8619 0.468792 11.9758 2.32906 10.649C3.38905 9.89303 3.41709 8.29487 2.38295 7.57749C0.568046 6.31848 2.24476 3.37247 4.22705 4.33737C5.35656 4.88718 6.70692 4.06347 6.82233 2.75427Z"
                        stroke="white"
                        stroke-width="1.5"
                      />
                      <path
                        d="M8.5 7C9.60457 7 10.5 7.89543 10.5 9V9C10.5 10.1046 9.60457 11 8.5 11V11C7.39543 11 6.5 10.1046 6.5 9V9C6.5 7.89543 7.39543 7 8.5 7V7Z"
                        stroke="white"
                        stroke-width="1.5"
                      />
                    </svg>
                    <span className="-mr-1 text-sm font-medium">
                      System Settings & Security
                    </span>
                  </NavLink>
                </li>
              )}

              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/support"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  hover:bg-[#FFFFFF12] hover:text-[#fff] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_43_382)">
                      <path
                        d="M16.1072 12.2143H17.4643C17.8242 12.2143 18.1694 12.0788 18.4239 11.8377C18.6785 11.5966 18.8214 11.2696 18.8214 10.9286V9C18.8214 8.65901 18.6785 8.33198 18.4239 8.09086C18.1694 7.84975 17.8242 7.71429 17.4643 7.71429H16.1072C15.9272 7.71429 15.7546 7.78202 15.6273 7.90258C15.5001 8.02313 15.4286 8.18665 15.4286 8.35714V11.5714C15.4286 11.7419 15.5001 11.9054 15.6273 12.026C15.7546 12.1466 15.9272 12.2143 16.1072 12.2143ZM16.1072 12.2143V13.1786C16.1072 13.8606 15.8212 14.5146 15.3122 14.9968C14.8031 15.4791 14.1127 15.75 13.3929 15.75M2.53573 7.71429H3.89287C4.07284 7.71429 4.24544 7.78202 4.3727 7.90258C4.49995 8.02313 4.57145 8.18665 4.57145 8.35714V11.5714C4.57145 11.7419 4.49995 11.9054 4.3727 12.026C4.24544 12.1466 4.07284 12.2143 3.89287 12.2143H2.53573C2.1758 12.2143 1.8306 12.0788 1.57609 11.8377C1.32157 11.5966 1.17859 11.2696 1.17859 10.9286V9C1.17859 8.65901 1.32157 8.33198 1.57609 8.09086C1.8306 7.84975 2.1758 7.71429 2.53573 7.71429Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.89288 7.71429V6.42857C3.89288 4.89411 4.53631 3.42249 5.68162 2.33746C6.82694 1.25242 8.38031 0.64286 10 0.64286C11.6197 0.64286 13.1731 1.25242 14.3184 2.33746C15.4637 3.42249 16.1072 4.89411 16.1072 6.42857V7.71429M7.96431 5.14286V7.07143M12.0357 5.14286V7.07143M7.96431 9.64286C7.96431 11.3529 12.0357 11.3529 12.0357 9.64286M11.6965 14.1429C12.1464 14.1429 12.5779 14.3122 12.896 14.6136C13.2142 14.915 13.3929 15.3238 13.3929 15.75C13.3929 16.1762 13.2142 16.585 12.896 16.8864C12.5779 17.1878 12.1464 17.3571 11.6965 17.3571H9.66074C9.21082 17.3571 8.77933 17.1878 8.46118 16.8864C8.14304 16.585 7.96431 16.1762 7.96431 15.75C7.96431 15.3238 8.14304 14.915 8.46118 14.6136C8.77933 14.3122 9.21082 14.1429 9.66074 14.1429H11.6965Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_43_382">
                        <rect
                          width="19"
                          height="18"
                          fill="white"
                          transform="translate(0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="-mr-1 text-sm font-medium">
                    Customer Support Centre
                  </span>
                </NavLink>
              </li>

              <li className="min-w-max mb-5 mt-16 ">
                <button
                  onClick={logoutHandler}
                  className="flex justify-center w-full items-center  py-4 text-base font-semibold text-[#fff] capitalize transition-colors duration-300 transform  bg-[#FFFFFF12] hover:bg-gray-600 hover:text-[#fff] gap-x-1 rounded-lg"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 8L3 12M3 12L7 16M3 12L17 12M11 8L11 7C11 6.20435 11.3161 5.44129 11.8787 4.87868C12.4413 4.31607 13.2044 4 14 4L18 4C18.7957 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7L21 17C21 17.7956 20.6839 18.5587 20.1213 19.1213C19.5587 19.6839 18.7956 20 18 20L14 20C13.2044 20 12.4413 19.6839 11.8787 19.1213C11.3161 18.5587 11 17.7956 11 17L11 16"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <span className=" text-sm font-medium">Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <main
        className={`bg-[#fff] 
				 flex flex-grow flex-col  transition-all duration-150 ease-in  rounded-tl-xl w-full lg:w-auto`}
      >
        <Outlet />
      </main>
    </div>
  );
}

export default DashboardLayout;
