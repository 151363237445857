import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AdminPrivilegesForm from '../../../components/AdminPrivilegesForm';
import Loading from '../../../components/Loading';
import { useGetAllAdminsQuery } from '../../../Slices/userApsSlice';

function ManageAccounts() {
	// const [isOpen, setIsOpen] = useState(false);

	const [user, setUser] = useState([]);
	const [reFetch, setReFetch] = useState(false);

	const { data, isLoading, refetch, error } = useGetAllAdminsQuery();

	useEffect(() => {
		if (data) {
			setUser(data);
		}
		if (reFetch) {
			refetch();
		}
	}, [data, reFetch, refetch]);

	return (
		<div className="px-5">
			<div className="flex justify-between gap-x-2  my-10 ">
				<div className="">
					<h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
						Home{' '}
						<span class=" text-gray-500 0 rtl:-scale-x-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>{' '}
						System Settings & Security
						<span class=" text-gray-500 0 rtl:-scale-x-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>
						Manage Accounts
					</h2>
					<Link
						to="/dashboard/settings"
						className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
								stroke="#0C0C0C"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
								stroke="#0C0C0C"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						Back
					</Link>
				</div>
			</div>
			<div class="grid grid-cols-1 lg:grid-cols-12 gap-4 ">
				<div className="sm:col-span-9">
					<div className="flex justify-between items-center  gap-x-2  my-5 ">
						<div className="">
							<h2 className="text-[#0C0C0C] font-extrabold text-2xl py-1 text-left  block ">
								Admins
							</h2>
							<h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
								This is a list of all Admins
							</h2>
						</div>
					</div>

					<section class="container   rounded mx-auto">
						<div class="flex flex-col">
							<div class=" overflow-x-auto scrollbar-hide ">
								<div class="inline-block min-w-full py-2 align-middle ">
									<div class="overflow-hidden md:rounded-lg">
										<div class="min-w-full ">
											{isLoading ? (
												<div className="pb-5">
													<Loading />
												</div>
											) : error ? (
												<div className="pt-5">
													<Loading />
												</div>
											) : (
												<>
													<ul className="space-y-4 my-3">
														{user.map((item, i) => (
															<li key={i} className="text-left">
																<label
																	for={'accordion-1' + i}
																	className="relative flex flex-col rounded-md bg-[#F9FAFB]"
																>
																	<input
																		className="peer hidden"
																		type="checkbox"
																		id={'accordion-1' + i}
																	/>

																	<svg
																		width="12"
																		height="8"
																		viewBox="0 0 12 8"
																		fill="none"
																		className="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M11 1.5L6 6.5L1 1.5"
																			stroke="black"
																			stroke-width="2"
																		/>
																	</svg>
																	<div className="relative cursor-pointer select-none items-center  pr-16">
																		<div className=" rounded-lg flex items-center gap-x-20  ">
																			<div className="flex w-[20rem]">
																				<div class="px-5 text-sm font-medium text-gray-700 bg-[#E7E7E7] whitespace-nowrap">
																					<div class="py-3 flex flex-wrap content-center justify-center">
																						<svg
																							width="35"
																							height="35"
																							viewBox="0 0 35 35"
																							fill="none"
																							xmlns="http://www.w3.org/2000/svg"
																						>
																							<path
																								d="M17.5 0C7.84 0 0 7.84 0 17.5C0 27.16 7.84 35 17.5 35C27.16 35 35 27.16 35 17.5C35 7.84 27.16 0 17.5 0ZM17.5 7C20.8775 7 23.625 9.7475 23.625 13.125C23.625 16.5025 20.8775 19.25 17.5 19.25C14.1225 19.25 11.375 16.5025 11.375 13.125C11.375 9.7475 14.1225 7 17.5 7ZM17.5 31.5C13.9475 31.5 9.7475 30.065 6.755 26.46C9.82031 24.055 13.6038 22.7479 17.5 22.7479C21.3962 22.7479 25.1797 24.055 28.245 26.46C25.2525 30.065 21.0525 31.5 17.5 31.5Z"
																								fill="white"
																							/>
																						</svg>
																					</div>
																				</div>

																				<div class="px-3 py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
																					{item.name}
																				</div>
																			</div>
																			<div class="px-3 py-5 text-sm font-light  text-[#111827] whitespace-nowrap ">
																				{item.role}
																			</div>
																		</div>
																	</div>
																	<div class="peer-checked:hidden ">
																		<AdminPrivilegesForm
																			items={item}
																			setReFetch={setReFetch}
																		/>
																	</div>
																</label>
															</li>
														))}
													</ul>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	);
}

export default ManageAccounts;
