import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormattedDate from "../../../components/FormattedDate";
import {
  useCreateAdminMutation,
  useGetResolutionQuery,
} from "../../../Slices/userApsSlice";
import Loading from "../../../components/Loading";
import Spinner from "../../../components/Spinner";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../../utils";

function Report() {
  const [modal, setModal] = useState(false);
  const [resolution, setResolution] = useState([]);
  const [singleResolution, setSingleResolution] = useState([]);
  const { data, isLoading: loading, error } = useGetResolutionQuery();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (data) {
      setResolution(data.customerResolution);
    }
  }, [data]);
  const [createModal, setCreateModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [createAdmin, { isLoading }] = useCreateAdminMutation();
  const { register, handleSubmit } = useForm();
  const createHandler = async (data) => {
    try {
      const res = await createAdmin(data);
      toast.success(res.data.message);
      setCreateModal(false);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const getHandler = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}admin/getCustomerResolution/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );

      // console.log(res.data.expenseTransaction)
      const response = res.data.customerResolution;
      setSingleResolution(response);
      setModal(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  return (
    <div className="px-5">
      <div className="flex justify-between gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            Home{" "}
            <span className=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Customer Support Centre
            <span className=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Message
          </h2>
          <Link
            to="/dashboard/support"
            className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-10"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back
          </Link>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 my-10">
        <div className="sm:col-span-9 px-5">
          <div className="flex justify-between items-center  gap-x-2  mb-5 ">
            <div className="">
              <h2 className="text-[#0C0C0C] font-extrabold text-xl py-1 text-left  block ">
                Report
              </h2>
            </div>

            <div className="flex justify-between items-center  gap-x-1  ">
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.5094 13.681C24.5094 7.69789 19.6591 2.84764 13.6761 2.84764C7.69302 2.84764 2.84277 7.69789 2.84277 13.681C2.84277 19.664 7.69302 24.5143 13.6761 24.5143C19.6591 24.5143 24.5094 19.664 24.5094 13.681Z"
                  stroke="#141B34"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.34277 14.2226H18.0093"
                  stroke="#141B34"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.5088 18.0143H15.8421"
                  stroke="#141B34"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.25879 10.431H19.0921"
                  stroke="#141B34"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
              Filter:
              <div className="w-[4rem] ">
                <div className="relative">
                  <select className="py-2 my-1 block appearance-none cursor-pointer text-[#979797] bg-[#FFF] rounded-sm w-full focus:outline-none  font-thin text-sm">
                    <option selected>Newest</option>
                    <option value="male">Monthly</option>
                    <option value="female">Yearly</option>
                  </select>
                </div>
              </div>
              <button
                // onClick={(e) => {
                // 	e.preventDefault();
                // 	navigate('/edit_address');
                // }}
                className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#CA0D0D]   px-5 py-2 rounded-lg "
              >
                <svg
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4375 4.35413L14.9469 12.2907C14.8215 14.3184 14.7589 15.3322 14.2506 16.0612C13.9993 16.4216 13.6758 16.7257 13.3006 16.9543C12.5417 17.4166 11.5259 17.4166 9.49422 17.4166C7.45997 17.4166 6.44282 17.4166 5.68341 16.9534C5.30797 16.7245 4.98433 16.4198 4.73312 16.0588C4.22503 15.3287 4.16373 14.3134 4.04115 12.2828L3.5625 4.35413"
                    stroke="#CA0D0D"
                    stroke-linecap="round"
                  />
                  <path
                    d="M16.625 4.35413H2.375"
                    stroke="#CA0D0D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M12.712 4.35421L12.1716 3.23933C11.8125 2.49875 11.6331 2.12845 11.3234 1.89752C11.2547 1.84629 11.182 1.80072 11.106 1.76126C10.7631 1.58337 10.3516 1.58337 9.52857 1.58337C8.68489 1.58337 8.26301 1.58337 7.91448 1.76872C7.83722 1.8098 7.76351 1.85721 7.69409 1.91047C7.38086 2.15076 7.20589 2.5346 6.85596 3.30229L6.37646 4.35421"
                    stroke="#CA0D0D"
                    stroke-linecap="round"
                  />
                  <path
                    d="M7.521 13.0625V8.3125"
                    stroke="#CA0D0D"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11.479 13.0625V8.3125"
                    stroke="#CA0D0D"
                    stroke-linecap="round"
                  />
                </svg>
                Delete
              </button>
            </div>
          </div>

          <div className="flex flex-col my-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  {loading ? (
                    <div className="pb-5">
                      <Loading />
                    </div>
                  ) : error ? (
                    <div className="pt-5">
                      <Loading />
                    </div>
                  ) : (
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="flex gap-x-2 items-center p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                          >
                            <div className="my-1 flex flex-wrap content-center  gap-x-2 ">
                              <div className="flex -ml-2 sm:ml-0 sm:mr-3">
                                <input
                                  id="default-checkbox"
                                  type="checkbox"
                                  value=""
                                  name="default-checkbox"
                                  className="peer opacity-0 cursor-pointer w-3 h-3   bg-gray-100 border-gray-300 focus:ring-blue-500 "
                                />
                                <label
                                  for="default-checkbox"
                                  className="flex cursor-pointer  justify-center items-center h-[15px] min-h-[15px] w-[15px] min-w-[15px] sm:h-[20px] sm:min-h-[20px] sm:w-[20px] sm:min-w-[20px] items-center 
								-ml-3	text-center rounded border border-gray-200 peer-checked:bg-[#264A83] peer-checked:text-white peer-checked:border-[#4CC5D2] text-[17px] text-sm font-medium text-gray-300 "
                                >
                                  <svg
                                    width="11"
                                    height="8"
                                    viewBox="0 0 11 8"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9.16665 1.75L4.12498 6.79167L1.83331 4.5"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </label>
                              </div>
                            </div>
                            Message
                          </th>
                          <th
                            scope="col"
                            className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                          >
                            Sender
                          </th>
                          <th
                            scope="col"
                            className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                          >
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {resolution.map((item, i) => (
                          <tr
                            key={i}
                            className={i % 2 === 0 ? "" : "bg-gray-50"}
                            onClick={() => getHandler(item.id)}
                          >
                            <td className="p-4  text-sm font-normal text-gray-900 flex gap-x-2 ">
                              <div className="my-1 flex flex-wrap content-center  gap-x-2 ">
                                <div className="flex -ml-2 sm:ml-0 sm:mr-3">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    value=""
                                    name="default-checkbox"
                                    className="peer opacity-0 cursor-pointer w-3 h-3   bg-gray-100 border-gray-300 focus:ring-blue-500 "
                                  />
                                  <label
                                    for="default-checkbox"
                                    className="flex cursor-pointer  justify-center items-center h-[15px] min-h-[15px] w-[15px] min-w-[15px] sm:h-[20px] sm:min-h-[20px] sm:w-[20px] sm:min-w-[20px] items-center 
										-ml-3	text-center rounded border border-gray-200 peer-checked:bg-[#264A83] peer-checked:text-white peer-checked:border-[#4CC5D2] text-[17px] text-sm font-medium text-gray-300 "
                                  >
                                    <svg
                                      width="11"
                                      height="8"
                                      viewBox="0 0 11 8"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M9.16665 1.75L4.12498 6.79167L1.83331 4.5"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                  </label>
                                </div>
                              </div>

                              <div className="text-xs w-[17rem]">
                                {item.message}
                              </div>
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                              <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                  <img
                                    className="h-8 w-8 rounded-full"
                                    src={item.image}
                                    alt={item.name}
                                  />
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-semibold text-gray-900 truncate">
                                    {item.name}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate cursor-pointer">
                                    {item.email}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                              <FormattedDate date={item?.created_at} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:col-span-3">
          <div className=" my-3 p-3 bg-gradient-to-b from-[#284C84]  to-[#062F70] rounded-md  ">
            <h2 className="text-[#fff] font-semibold text-base px-4 py-2 text-left  block ">
              Quick Access
            </h2>
            <button
              onClick={() => {
                setCreateModal(true);
              }}
              className="flex items-center gap-x-2 w-full text-left block px-3 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.13086 10.5C6.13086 10.3259 6.2 10.159 6.32307 10.036C6.44614 9.91288 6.61306 9.84374 6.78711 9.84374H9.84348V6.78737C9.84348 6.61332 9.91263 6.4464 10.0357 6.32333C10.1588 6.20026 10.3257 6.13112 10.4997 6.13112C10.6738 6.13112 10.8407 6.20026 10.9638 6.32333C11.0868 6.4464 11.156 6.61332 11.156 6.78737V9.84374H14.2124C14.3864 9.84374 14.5533 9.91288 14.6764 10.036C14.7995 10.159 14.8686 10.3259 14.8686 10.5C14.8686 10.674 14.7995 10.841 14.6764 10.964C14.5533 11.0871 14.3864 11.1562 14.2124 11.1562H11.156V14.2126C11.156 14.3867 11.0868 14.5536 10.9638 14.6767C10.8407 14.7997 10.6738 14.8689 10.4997 14.8689C10.3257 14.8689 10.1588 14.7997 10.0357 14.6767C9.91263 14.5536 9.84348 14.3867 9.84348 14.2126V11.1562H6.78711C6.61306 11.1562 6.44614 11.0871 6.32307 10.964C6.2 10.841 6.13086 10.674 6.13086 10.5Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.40279 3.29788C9.12619 2.99596 11.8746 2.99596 14.598 3.29788C16.1967 3.47638 17.4873 4.7355 17.6745 6.34288C17.9983 9.10525 17.9983 11.8956 17.6745 14.658C17.4864 16.2654 16.1958 17.5236 14.598 17.703C11.8746 18.0049 9.12619 18.0049 6.40279 17.703C4.80416 17.5236 3.51354 16.2654 3.32629 14.658C3.00322 11.8957 3.00322 9.10517 3.32629 6.34288C3.51354 4.7355 4.80504 3.47638 6.40279 3.29788ZM14.4528 4.60163C11.8259 4.31046 9.17491 4.31046 6.54804 4.60163C6.06175 4.65557 5.60785 4.87189 5.25967 5.21563C4.91148 5.55936 4.68935 6.01045 4.62916 6.496C4.31797 9.15685 4.31797 11.8449 4.62916 14.5058C4.68954 14.9911 4.91175 15.442 5.25991 15.7856C5.60808 16.1292 6.06188 16.3453 6.54804 16.3993C9.15291 16.6898 11.8479 16.6898 14.4528 16.3993C14.9388 16.3452 15.3924 16.1289 15.7404 15.7853C16.0884 15.4418 16.3104 14.991 16.3708 14.5058C16.682 11.8449 16.682 9.15685 16.3708 6.496C16.3103 6.01089 16.0881 5.56032 15.7401 5.21695C15.3922 4.87357 14.9387 4.65743 14.4528 4.60338V4.60163Z"
                  fill="white"
                />
              </svg>
              Add Admin Account
            </button>
            <Link
              to="/dashboard/users"
              className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_266_4334)">
                  <path
                    d="M5.33366 7.33333C5.15685 7.33333 4.98728 7.40357 4.86225 7.52859C4.73723 7.65361 4.66699 7.82318 4.66699 7.99999C4.66699 8.17681 4.73723 8.34637 4.86225 8.4714C4.98728 8.59642 5.15685 8.66666 5.33366 8.66666H10.667C10.8438 8.66666 11.0134 8.59642 11.1384 8.4714C11.2634 8.34637 11.3337 8.17681 11.3337 7.99999C11.3337 7.82318 11.2634 7.65361 11.1384 7.52859C11.0134 7.40357 10.8438 7.33333 10.667 7.33333H5.33366Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.3337 7.99999C15.3337 12.05 12.0503 15.3333 8.00033 15.3333C3.95033 15.3333 0.666992 12.05 0.666992 7.99999C0.666992 3.94999 3.95033 0.666656 8.00033 0.666656C12.0503 0.666656 15.3337 3.94999 15.3337 7.99999ZM14.0003 7.99999C14.0003 8.78792 13.8451 9.56814 13.5436 10.2961C13.2421 11.024 12.8001 11.6855 12.243 12.2426C11.6858 12.7998 11.0244 13.2417 10.2964 13.5433C9.56847 13.8448 8.78826 14 8.00033 14C7.21239 14 6.43218 13.8448 5.70423 13.5433C4.97627 13.2417 4.31484 12.7998 3.75768 12.2426C3.20053 11.6855 2.75858 11.024 2.45705 10.2961C2.15552 9.56814 2.00033 8.78792 2.00033 7.99999C2.00033 6.40869 2.63247 4.88257 3.75768 3.75735C4.8829 2.63213 6.40903 1.99999 8.00033 1.99999C9.59162 1.99999 11.1177 2.63213 12.243 3.75735C13.3682 4.88257 14.0003 6.40869 14.0003 7.99999Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_266_4334">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Ban Admin
            </Link>

            <Link
              to="/dashboard/settings/accounts"
              className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8125 3.89583L13.3735 10.9969C13.2613 12.8112 13.2053 13.7184 12.7506 14.3706C12.5257 14.693 12.2362 14.9652 11.9005 15.1697C11.2215 15.5833 10.3126 15.5833 8.49483 15.5833C6.67471 15.5833 5.76463 15.5833 5.08516 15.1689C4.74923 14.964 4.45967 14.6914 4.2349 14.3684C3.78029 13.7152 3.72544 12.8067 3.61577 10.9899L3.1875 3.89583"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M14.875 3.89583H2.125"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M11.3737 3.89584L10.8902 2.89831C10.5689 2.23569 10.4083 1.90437 10.1313 1.69775C10.0698 1.65191 10.0048 1.61114 9.93674 1.57583C9.62996 1.41667 9.26177 1.41667 8.52538 1.41667C7.77051 1.41667 7.39304 1.41667 7.0812 1.58251C7.01207 1.61926 6.94612 1.66168 6.88401 1.70933C6.60375 1.92433 6.4472 2.26777 6.1341 2.95465L5.70508 3.89584"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M6.72949 11.6875V7.4375"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M10.2705 11.6875V7.4375"
                  stroke="white"
                  stroke-linecap="round"
                />
              </svg>
              Remove Admin Account
            </Link>
          </div>
        </div>
      </div>
      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-2xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Back to info
                </button>
              </div>

              <div className="py-2">
                <span className="relative inline-block px-3 py-1 font-semibold text-[#BC9706] leading-tight">
                  <span
                    aria-hidden
                    className="absolute inset-0 bg-[#FAF8F2] opacity-50 rounded-full"
                  ></span>
                  <span className="relative text-sm font-light">
                    Dispute error unresolved
                  </span>
                </span>
                <h3 className="text-[#000] text-lg font-semibold py-3">
                  {singleResolution.transaction_type} Info
                </h3>
              </div>
              {/* <h2 className="text-[#000]  text-left   text-xs font-semibold">
                Approved by: ADM109399
              </h2> */}
              <div className="flex items-center gap-x-4 mb-4">
                <div className="flex items-center w-full">
                  <svg
                    className="w-16"
                    width="134"
                    height="134"
                    viewBox="0 0 134 134"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M67 11.1667C36.18 11.1667 11.1667 36.18 11.1667 67C11.1667 97.82 36.18 122.833 67 122.833C97.82 122.833 122.833 97.82 122.833 67C122.833 36.18 97.82 11.1667 67 11.1667ZM67 33.5C77.7758 33.5 86.5417 42.2658 86.5417 53.0417C86.5417 63.8175 77.7758 72.5833 67 72.5833C56.2242 72.5833 47.4583 63.8175 47.4583 53.0417C47.4583 42.2658 56.2242 33.5 67 33.5ZM67 111.667C55.6658 111.667 42.2658 107.088 32.7183 95.5867C42.4981 87.9136 54.5694 83.7433 67 83.7433C79.4306 83.7433 91.5019 87.9136 101.282 95.5867C91.7342 107.088 78.3342 111.667 67 111.667Z"
                      fill="#E7E7E7"
                    />
                  </svg>
                  <div class="flex flex-col ">
                    <div class="text-[#111827] text-sm text-left font-semibold">
                      {singleResolution.name}
                    </div>
                    <div class="flex w-full  text-[#6B7280] font-thin text-xs">
                      {singleResolution.email}
                    </div>
                  </div>
                </div>
                <div className="w-full py-1">
                  <h2 className="text-[#000] text-left  text-xs font-semibold">
                    Reference Number
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {singleResolution.reference_numbe}
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-x-4 mb-4">
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-xs font-semibold">
                    Transaction Date & Time
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    <FormattedDate date={singleResolution.created_at} />
                  </div>
                </div>
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-xs font-semibold">
                    Transaction type
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {singleResolution.transaction_type}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-x-4 mb-4">
                {/* <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-xs font-semibold">
                    Amount
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {/* {singleTrans.amount_currency} {singleTrans.amount} */}
                {/* </div>
                  <div className="w-full py-1">
                    <h2 className="text-[#0C0C0C]  text-center text-sm font-semibold">
                      <svg
                        className="mx-auto my-1"
                        width="20"
                        height="13"
                        viewBox="0 0 20 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 3.13184C4 0.203219 7 1.06033 10 3.20313C13 5.34591 16 6.20304 19 3.27442"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1 10.1631C4 7.23446 7 8.09159 10 10.2344C13 12.3772 16 13.2343 19 10.3057"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </h2>
                    <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left"></div>
                  </div>
                </div>  */}
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-xs font-semibold">
                    Message
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {singleResolution.message}
                  </div>
                </div>
              </div>
              {/* <div className="flex items-center gap-x-4 mb-4">
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-xs font-semibold">
                    Asset
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] p-4  text-xs font-normal lightnded-lg mt-1 text-left">
                    BITCOIN
                  </div>
                </div>
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-xs font-semibold ">
                    Transaction Status
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] p-4  text-xs font-normal  rounded-lg mt-1 text-left">
                    SUCCESSFUL
                  </div>
                </div>
              </div> */}

              <button
                onClick={() => {
                  setModal(false);
                }}
                className=" w-[18rem] transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#00795A]  px-3 py-4 rounded-lg "
              >
                Confirm error resolution
              </button>
            </div>
          </div>
        </div>
      )}
      {createModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCreateModal(false);
                  }}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm  ml-auto inline-flex items-center "
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Create New Admin Account
              </h3>

              <form onSubmit={handleSubmit(createHandler)}>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Full Name *
                  </label>
                  <input
                    type="text"
                    name="Name"
                    required
                    {...register("name")}
                    placeholder="Full Name"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    {...register("email")}
                    placeholder="Email Address"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div>
                  <label
                    for="role"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Role *
                  </label>
                  <input
                    type="text"
                    name="role"
                    required
                    {...register("role")}
                    value="Admin"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div className="mt-2">
                  <label
                    for="password"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Password *
                  </label>
                  <div className="flex ">
                    <input
                      {...register("password")}
                      required
                      type={passwordType}
                      name="password"
                      id="password"
                      placeholder="Your Password"
                      className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                      <button
                        type="button"
                        onClick={() => {
                          if (passwordType === "password") {
                            setPasswordType("text");
                            return;
                          }
                          setPasswordType("password");
                        }}
                        className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                      >
                        {passwordType === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_5982_274025)">
                              <path
                                d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1 1L23 23"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_5982_274025">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                              fill="#757E7E"
                            />
                          </svg>
                        )}
                      </button>
                    </span>
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#284C84] rounded-sm hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    {isLoading ? <Spinner /> : "Create New Admin Account"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Report;
