import React from 'react';
import { Link } from 'react-router-dom';

function Notification() {
	return (
		<div className="px-5">
			<div className="flex justify-between gap-x-2  my-10 ">
				<div className="">
					<h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
						Home{' '}
						<span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>{' '}
						System Settings & Security
						<span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>
						Notification
					</h2>
					<Link
						to="/dashboard/settings"
						className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
								stroke="#0C0C0C"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
								stroke="#0C0C0C"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						Back
					</Link>
				</div>
			</div>
			<div class="grid grid-cols-1 lg:grid-cols-12 gap-4 my-10">
				<div className="sm:col-span-9">
					<div className="flex justify-between items-center  gap-x-2  mb-5 ">
						<div className="">
							<h2 className="text-[#0C0C0C] font-extrabold text-xl py-1 text-left  block ">
								Notification Settings
							</h2>
							<h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
								Select which notifications you would like to receive
							</h2>
						</div>
					</div>

					<div className="border rounded w-[40rem]">
						<div className="border-b flex gap-x-5 p-5">
							<div class="my-1 flex flex-wrap content-center  gap-x-2 ">
								<div className="flex -ml-2 sm:ml-0 sm:mr-3">
									<input
										id="default-checkbox"
										type="checkbox"
										value=""
										name="default-checkbox"
										className="peer opacity-0 cursor-pointer w-3 h-3   bg-gray-100 border-gray-300 focus:ring-blue-500 "
									/>
									<label
										for="default-checkbox"
										className="flex cursor-pointer  justify-center items-center h-[15px] min-h-[15px] w-[15px] min-w-[15px] sm:h-[20px] sm:min-h-[20px] sm:w-[20px] sm:min-w-[20px] items-center 
										-ml-3	text-center rounded border border-gray-200 peer-checked:bg-[#264A83] peer-checked:text-white peer-checked:border-[#4CC5D2] text-[17px] text-sm font-medium text-gray-300 "
									>
										<svg
											width="11"
											height="8"
											viewBox="0 0 11 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.16665 1.75L4.12498 6.79167L1.83331 4.5"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</label>
								</div>
							</div>
							<div className="">
								<h2 className="text-[#0C0C0C] font-semibold text-sm py-1 text-left  block ">
									Gift Card Trade Request
								</h2>
								<h2 className="text-[#4E4E4E] text-sm font-light text-left ">
									When a user requests a trade
								</h2>
							</div>
						</div>
						<div className="border-b flex gap-x-5 p-5">
							<div class="my-1 flex flex-wrap content-center  gap-x-2 ">
								<div className="flex -ml-2 sm:ml-0 sm:mr-3">
									<input
										id="default-checkbox"
										type="checkbox"
										value=""
										name="default-checkbox"
										className="peer opacity-0 cursor-pointer w-3 h-3   bg-gray-100 border-gray-300 focus:ring-blue-500 "
									/>
									<label
										for="default-checkbox"
										className="flex cursor-pointer  justify-center items-center h-[15px] min-h-[15px] w-[15px] min-w-[15px] sm:h-[20px] sm:min-h-[20px] sm:w-[20px] sm:min-w-[20px] items-center 
										-ml-3	text-center rounded border border-gray-200 peer-checked:bg-[#264A83] peer-checked:text-white peer-checked:border-[#4CC5D2] text-[17px] text-sm font-medium text-gray-300 "
									>
										<svg
											width="11"
											height="8"
											viewBox="0 0 11 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.16665 1.75L4.12498 6.79167L1.83331 4.5"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</label>
								</div>
							</div>
							<div className="">
								<h2 className="text-[#0C0C0C] font-semibold text-sm py-1 text-left  block ">
									Gift Card Purchase Request
								</h2>
								<h2 className="text-[#4E4E4E] text-sm font-light text-left ">
									When someone bids on one of your coins
								</h2>
							</div>
						</div>
						<div className="border-b flex gap-x-5 p-5">
							<div class="my-1 flex flex-wrap content-center  gap-x-2 ">
								<div className="flex -ml-2 sm:ml-0 sm:mr-3">
									<input
										id="default-checkbox"
										type="checkbox"
										value=""
										name="default-checkbox"
										className="peer opacity-0 cursor-pointer w-3 h-3   bg-gray-100 border-gray-300 focus:ring-blue-500 "
									/>
									<label
										for="default-checkbox"
										className="flex cursor-pointer  justify-center items-center h-[15px] min-h-[15px] w-[15px] min-w-[15px] sm:h-[20px] sm:min-h-[20px] sm:w-[20px] sm:min-w-[20px] items-center 
										-ml-3	text-center rounded border border-gray-200 peer-checked:bg-[#264A83] peer-checked:text-white peer-checked:border-[#4CC5D2] text-[17px] text-sm font-medium text-gray-300 "
									>
										<svg
											width="11"
											height="8"
											viewBox="0 0 11 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.16665 1.75L4.12498 6.79167L1.83331 4.5"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</label>
								</div>
							</div>
							<div className="">
								<h2 className="text-[#0C0C0C] font-semibold text-sm py-1 text-left  block ">
									Utility Bill Transaction
								</h2>
								<h2 className="text-[#4E4E4E] text-sm font-light text-left ">
									When a user initiates an Utility bill payment
								</h2>
							</div>
						</div>
						<div className=" flex gap-x-5 p-5">
							<div class="my-1 flex flex-wrap content-center  gap-x-2 ">
								<div className="flex -ml-2 sm:ml-0 sm:mr-3">
									<input
										id="default-checkbox"
										type="checkbox"
										value=""
										name="default-checkbox"
										className="peer opacity-0 cursor-pointer w-3 h-3   bg-gray-100 border-gray-300 focus:ring-blue-500 "
									/>
									<label
										for="default-checkbox"
										className="flex cursor-pointer  justify-center items-center h-[15px] min-h-[15px] w-[15px] min-w-[15px] sm:h-[20px] sm:min-h-[20px] sm:w-[20px] sm:min-w-[20px] items-center 
										-ml-3	text-center rounded border border-gray-200 peer-checked:bg-[#264A83] peer-checked:text-white peer-checked:border-[#4CC5D2] text-[17px] text-sm font-medium text-gray-300 "
									>
										<svg
											width="11"
											height="8"
											viewBox="0 0 11 8"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.16665 1.75L4.12498 6.79167L1.83331 4.5"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</label>
								</div>
							</div>
							<div className="">
								<h2 className="text-[#0C0C0C] font-semibold text-sm py-1 text-left  block ">
									Customer Feedback Messages
								</h2>
								<h2 className="text-[#4E4E4E] text-sm font-light text-left ">
									When a user sends in a feedback
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="sm:col-span-3">
					<div className=" my-3 p-3 bg-gradient-to-b from-[#284C84]  to-[#062F70] rounded-md  ">
						<h2 className="text-[#fff] font-semibold text-base px-4 py-2 text-left  block ">
							Quick Access
						</h2>
						<button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_266_4334)">
									<path
										d="M5.33366 7.33333C5.15685 7.33333 4.98728 7.40357 4.86225 7.52859C4.73723 7.65361 4.66699 7.82318 4.66699 7.99999C4.66699 8.17681 4.73723 8.34637 4.86225 8.4714C4.98728 8.59642 5.15685 8.66666 5.33366 8.66666H10.667C10.8438 8.66666 11.0134 8.59642 11.1384 8.4714C11.2634 8.34637 11.3337 8.17681 11.3337 7.99999C11.3337 7.82318 11.2634 7.65361 11.1384 7.52859C11.0134 7.40357 10.8438 7.33333 10.667 7.33333H5.33366Z"
										fill="white"
									/>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M15.3337 7.99999C15.3337 12.05 12.0503 15.3333 8.00033 15.3333C3.95033 15.3333 0.666992 12.05 0.666992 7.99999C0.666992 3.94999 3.95033 0.666656 8.00033 0.666656C12.0503 0.666656 15.3337 3.94999 15.3337 7.99999ZM14.0003 7.99999C14.0003 8.78792 13.8451 9.56814 13.5436 10.2961C13.2421 11.024 12.8001 11.6855 12.243 12.2426C11.6858 12.7998 11.0244 13.2417 10.2964 13.5433C9.56847 13.8448 8.78826 14 8.00033 14C7.21239 14 6.43218 13.8448 5.70423 13.5433C4.97627 13.2417 4.31484 12.7998 3.75768 12.2426C3.20053 11.6855 2.75858 11.024 2.45705 10.2961C2.15552 9.56814 2.00033 8.78792 2.00033 7.99999C2.00033 6.40869 2.63247 4.88257 3.75768 3.75735C4.8829 2.63213 6.40903 1.99999 8.00033 1.99999C9.59162 1.99999 11.1177 2.63213 12.243 3.75735C13.3682 4.88257 14.0003 6.40869 14.0003 7.99999Z"
										fill="white"
									/>
								</g>
								<defs>
									<clipPath id="clip0_266_4334">
										<rect width="16" height="16" fill="white" />
									</clipPath>
								</defs>
							</svg>
							Ban Admin
						</button>
						<button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
							<svg
								width="21"
								height="21"
								viewBox="0 0 21 21"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.13086 10.5C6.13086 10.3259 6.2 10.159 6.32307 10.036C6.44614 9.91288 6.61306 9.84374 6.78711 9.84374H9.84348V6.78737C9.84348 6.61332 9.91263 6.4464 10.0357 6.32333C10.1588 6.20026 10.3257 6.13112 10.4997 6.13112C10.6738 6.13112 10.8407 6.20026 10.9638 6.32333C11.0868 6.4464 11.156 6.61332 11.156 6.78737V9.84374H14.2124C14.3864 9.84374 14.5533 9.91288 14.6764 10.036C14.7995 10.159 14.8686 10.3259 14.8686 10.5C14.8686 10.674 14.7995 10.841 14.6764 10.964C14.5533 11.0871 14.3864 11.1562 14.2124 11.1562H11.156V14.2126C11.156 14.3867 11.0868 14.5536 10.9638 14.6767C10.8407 14.7997 10.6738 14.8689 10.4997 14.8689C10.3257 14.8689 10.1588 14.7997 10.0357 14.6767C9.91263 14.5536 9.84348 14.3867 9.84348 14.2126V11.1562H6.78711C6.61306 11.1562 6.44614 11.0871 6.32307 10.964C6.2 10.841 6.13086 10.674 6.13086 10.5Z"
									fill="white"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M6.40279 3.29788C9.12619 2.99596 11.8746 2.99596 14.598 3.29788C16.1967 3.47638 17.4873 4.7355 17.6745 6.34288C17.9983 9.10525 17.9983 11.8956 17.6745 14.658C17.4864 16.2654 16.1958 17.5236 14.598 17.703C11.8746 18.0049 9.12619 18.0049 6.40279 17.703C4.80416 17.5236 3.51354 16.2654 3.32629 14.658C3.00322 11.8957 3.00322 9.10517 3.32629 6.34288C3.51354 4.7355 4.80504 3.47638 6.40279 3.29788ZM14.4528 4.60163C11.8259 4.31046 9.17491 4.31046 6.54804 4.60163C6.06175 4.65557 5.60785 4.87189 5.25967 5.21563C4.91148 5.55936 4.68935 6.01045 4.62916 6.496C4.31797 9.15685 4.31797 11.8449 4.62916 14.5058C4.68954 14.9911 4.91175 15.442 5.25991 15.7856C5.60808 16.1292 6.06188 16.3453 6.54804 16.3993C9.15291 16.6898 11.8479 16.6898 14.4528 16.3993C14.9388 16.3452 15.3924 16.1289 15.7404 15.7853C16.0884 15.4418 16.3104 14.991 16.3708 14.5058C16.682 11.8449 16.682 9.15685 16.3708 6.496C16.3103 6.01089 16.0881 5.56032 15.7401 5.21695C15.3922 4.87357 14.9387 4.65743 14.4528 4.60338V4.60163Z"
									fill="white"
								/>
							</svg>
							Add Admin Account
						</button>
						<button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
							<svg
								width="17"
								height="17"
								viewBox="0 0 17 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.8125 3.89583L13.3735 10.9969C13.2613 12.8112 13.2053 13.7184 12.7506 14.3706C12.5257 14.693 12.2362 14.9652 11.9005 15.1697C11.2215 15.5833 10.3126 15.5833 8.49483 15.5833C6.67471 15.5833 5.76463 15.5833 5.08516 15.1689C4.74923 14.964 4.45967 14.6914 4.2349 14.3684C3.78029 13.7152 3.72544 12.8067 3.61577 10.9899L3.1875 3.89583"
									stroke="white"
									stroke-linecap="round"
								/>
								<path
									d="M14.875 3.89583H2.125"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
								/>
								<path
									d="M11.3737 3.89584L10.8902 2.89831C10.5689 2.23569 10.4083 1.90437 10.1313 1.69775C10.0698 1.65191 10.0048 1.61114 9.93674 1.57583C9.62996 1.41667 9.26177 1.41667 8.52538 1.41667C7.77051 1.41667 7.39304 1.41667 7.0812 1.58251C7.01207 1.61926 6.94612 1.66168 6.88401 1.70933C6.60375 1.92433 6.4472 2.26777 6.1341 2.95465L5.70508 3.89584"
									stroke="white"
									stroke-linecap="round"
								/>
								<path
									d="M6.72949 11.6875V7.4375"
									stroke="white"
									stroke-linecap="round"
								/>
								<path
									d="M10.2705 11.6875V7.4375"
									stroke="white"
									stroke-linecap="round"
								/>
							</svg>
							Remove Admin Account
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Notification;
