import React from "react";
import { Link } from "react-router-dom";

function Events() {
  return (
    <div className="px-10">
      <div className="flex justify-between items-center  gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            <Link to="/dashboard/index">Home</Link>
            <span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            <Link to="/dashboard/merchant">Merchant Financial Base</Link>
            <span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Events
          </h2>
          <Link
            to="/dashboard/merchant"
            className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back
          </Link>
        </div>

        <div class="relative mx-3">
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg class="w-4 h-4 text-gray-400" viewBox="0 0 24 24" fill="none">
              <path
                d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </span>

          <input
            type="text"
            class="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
            placeholder="Search"
          />
        </div>
      </div>
      <div className="flex justify-between items-center  gap-x-2  ">
        <div>
          <h2 className="text-[#0C0C0C]   text-lg font-extrabold ">Events</h2>
        </div>

        <div className="w-[10rem] ">
          <div class="relative">
            <select class="py-2 my-1 block appearance-none border text-[#0C0C0C] bg-[#FFF] rounded-sm w-full focus:outline-none px-5 text-sm">
              <option selected>Select</option>
              <option value="male">Monthly</option>
              <option value="female">Yearly</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* Table+++++++++++++++++++++++++++++++ */}

      <div class="flex flex-col my-8">
        <div class="overflow-x-auto rounded-lg">
          <div class="align-middle inline-block min-w-full">
            <div class="shadow overflow-hidden sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      TRANSACTION
                    </th>
                    <th
                      scope="col"
                      class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      DATE & TIME
                    </th>
                    <th
                      scope="col"
                      class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      AMOUNT
                    </th>
                    <th
                      scope="col"
                      class="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      STATUS
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                  <tr>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                          fill="#141B34"
                        />
                        <path
                          d="M7.33337 6.41667H14.6667"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33337 10.0833H11"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Payment from{" "}
                      <span class="font-semibold">Bonnie Green</span>
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                      Apr 23 ,2021
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      $2300
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <span class="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                        <span
                          aria-hidden
                          class="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                        ></span>
                        <span class="relative text-xs font-normal">
                          In progress
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr class="bg-gray-50">
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left flex gap-x-2">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                          fill="#141B34"
                        />
                        <path
                          d="M7.33337 6.41667H14.6667"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33337 10.0833H11"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Payment refund to{" "}
                      <span class="font-semibold">#00910</span>
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                      Apr 23 ,2021
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      -$670
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <span class="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                        <span
                          aria-hidden
                          class="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                        ></span>
                        <span class="relative text-xs font-normal">
                          Cancelled
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                          fill="#141B34"
                        />
                        <path
                          d="M7.33337 6.41667H14.6667"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33337 10.0833H11"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Payment failed from{" "}
                      <span class="font-semibold">#087651</span>
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                      Apr 18 ,2021
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      $234
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <span class="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                        <span
                          aria-hidden
                          class="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                        ></span>
                        <span class="relative text-xs font-normal">
                          Cancelled
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr class="bg-gray-50">
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left flex gap-x-2">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                          fill="#141B34"
                        />
                        <path
                          d="M7.33337 6.41667H14.6667"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33337 10.0833H11"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Payment from <span class="font-semibold">Lana Byrd</span>
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                      Apr 15 ,2021
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      $5000
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                        <span
                          aria-hidden
                          class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                        ></span>
                        <span class="relative text-xs font-normal">
                          Completed
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                          fill="#141B34"
                        />
                        <path
                          d="M7.33337 6.41667H14.6667"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33337 10.0833H11"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Payment from <span class="font-semibold">Jese Leos</span>
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                      Apr 15 ,2021
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      $2300
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <span class="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                        <span
                          aria-hidden
                          class="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                        ></span>
                        <span class="relative text-xs font-normal">
                          Completed
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr class="bg-gray-50">
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left flex gap-x-2">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                          fill="#141B34"
                        />
                        <path
                          d="M7.33337 6.41667H14.6667"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33337 10.0833H11"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Payment from{" "}
                      <span class="font-semibold">THEMESBERG LLC</span>
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                      Apr 11 ,2021
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      $560
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <span class="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                        <span
                          aria-hidden
                          class="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                        ></span>
                        <span class="relative text-xs font-normal">
                          In progress
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                          fill="#141B34"
                        />
                        <path
                          d="M7.33337 6.41667H14.6667"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.33337 10.0833H11"
                          stroke="#141B34"
                          stroke-width="1.5"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Payment from <span class="font-semibold">Lana Lysle</span>
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                      Apr 6 ,2021
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      $1437
                    </td>
                    <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                      <span class="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                        <span
                          aria-hidden
                          class="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                        ></span>
                        <span class="relative text-xs font-normal">
                          In progress
                        </span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Events;
