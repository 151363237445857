import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import {
  useBanUserMutation,
  useGetAllUsersQuery,
} from "../../../Slices/userApsSlice";
import Search from "../../../components/Search";
import { Link } from "react-router-dom";

function BannedAccounts() {
  const [unbanModal, setUnbanModal] = useState(false);
  const [unbanSucess, setUnbanSucess] = useState(false);

  const [user, setUser] = useState([]);

  const { data, isLoading, refetch, error } = useGetAllUsersQuery();

  useEffect(() => {
    if (data) {
      setUser(data.users);
    }
  }, [data]);

  const [banuser] = useBanUserMutation();
  const unbanHandler = async (bandata) => {
    try {
      await banuser(bandata);
      setUnbanModal(false);
      setUnbanSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="px-5">
      <div className="flex justify-between gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            Home{" "}
            <span class=" text-gray-500 0 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Users
            <span class=" text-gray-500 0 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Baned Accounts
          </h2>

          <Link
            to="/dashboard/users"
            className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                stroke="#0C0C0C"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Back to users
          </Link>
        </div>


      </div>
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-4 my-10">
        <div className="sm:col-span-9">
          <div className="flex justify-between items-center  gap-x-2  my-5 ">
            <div className="">
              <h2 className="text-[#0C0C0C] font-extrabold text-2xl py-1 text-left  block ">
                Banned Accounts
              </h2>
              <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
                This is a list of all users.
              </h2>
            </div>

            {user
              .filter((item) => item.is_Banned === "true")
              .map((item) => (
                <h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-left  block ">
                  Total No users ({item.length})
                </h2>
              ))}
          </div>

          <section class="container   rounded mx-auto">
            <div class="flex flex-col">
              <div class=" overflow-x-auto scrollbar-hide ">
                <div class="inline-block min-w-full py-2 align-middle ">
                  <div class="overflow-hidden md:rounded-lg">
                    <div class="min-w-full ">
                      {/* .filter((item) => item.status === 'completed') */}
                      {isLoading ? (
                        <div className="pb-5">
                          <Loading />
                        </div>
                      ) : error ? (
                        <div className="pt-5">
                          <Loading />
                        </div>
                      ) : (
                        <>
                          {user
                            .filter((item) => item.is_Banned === "true")
                            .map((item) => (
                              <div
                                key={item.id}
                                className="my-2 rounded-lg flex items-center justify-between bg-[#F9FAFB]"
                              >
                                <div className="flex">
                                  <div class="px-5 text-sm font-medium text-gray-700 bg-[#E7E7E7] whitespace-nowrap">
                                    <div class="py-3 flex flex-wrap content-center justify-center">
                                      <svg
                                        width="35"
                                        height="35"
                                        viewBox="0 0 35 35"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17.5 0C7.84 0 0 7.84 0 17.5C0 27.16 7.84 35 17.5 35C27.16 35 35 27.16 35 17.5C35 7.84 27.16 0 17.5 0ZM17.5 7C20.8775 7 23.625 9.7475 23.625 13.125C23.625 16.5025 20.8775 19.25 17.5 19.25C14.1225 19.25 11.375 16.5025 11.375 13.125C11.375 9.7475 14.1225 7 17.5 7ZM17.5 31.5C13.9475 31.5 9.7475 30.065 6.755 26.46C9.82031 24.055 13.6038 22.7479 17.5 22.7479C21.3962 22.7479 25.1797 24.055 28.245 26.46C25.2525 30.065 21.0525 31.5 17.5 31.5Z"
                                          fill="white"
                                        />
                                      </svg>
                                    </div>
                                  </div>

                                  <div class="px-3 py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
                                    {item.full_name}
                                  </div>
                                </div>

                                <div class="px-3 text-sm text-[#fff] whitespace-nowrap ">
                                  <div className="flex gap-x-2 items-center justify-end">
                                    <button
                                      onClick={() => setUnbanModal(true)}
                                      className="flex gap-x-1 items-center bg-[#0AB02E] py-2 px-3 rounded-lg"
                                    >
                                      <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M10.75 4.89952H4.75V3.49945C4.74891 3.08376 4.88001 2.67711 5.12669 2.33102C5.37338 1.98492 5.72456 1.71494 6.13576 1.55527C6.54696 1.39561 6.99967 1.35343 7.43657 1.43409C7.87346 1.51475 8.27489 1.71461 8.59 2.00837C8.87196 2.27723 9.07364 2.61028 9.175 2.97442C9.19962 3.06359 9.24282 3.14736 9.30213 3.22095C9.36144 3.29454 9.4357 3.35651 9.52066 3.40331C9.60563 3.45011 9.69964 3.48084 9.79733 3.49373C9.89501 3.50662 9.99446 3.50143 10.09 3.47844C10.1855 3.45546 10.2753 3.41514 10.3541 3.35978C10.433 3.30442 10.4994 3.23511 10.5495 3.15581C10.5996 3.0765 10.6326 2.98876 10.6464 2.89758C10.6602 2.8064 10.6546 2.71357 10.63 2.6244C10.4591 2.01873 10.1203 1.46579 9.6475 1.02131C9.12263 0.532945 8.4545 0.200686 7.72747 0.066494C7.00043 -0.0676982 6.2471 0.00219351 5.56259 0.267344C4.87809 0.532494 4.2931 0.981012 3.88151 1.55626C3.46991 2.13152 3.25016 2.8077 3.25 3.49945V4.89952C2.65326 4.89952 2.08097 5.12078 1.65901 5.51463C1.23705 5.90847 1 6.44265 1 6.99963V11.8999C1 12.4569 1.23705 12.991 1.65901 13.3849C2.08097 13.7787 2.65326 14 3.25 14H10.75C11.3467 14 11.919 13.7787 12.341 13.3849C12.7629 12.991 13 12.4569 13 11.8999V6.99963C13 6.44265 12.7629 5.90847 12.341 5.51463C11.919 5.12078 11.3467 4.89952 10.75 4.89952ZM11.5 11.8999C11.5 12.0856 11.421 12.2636 11.2803 12.3949C11.1397 12.5262 10.9489 12.5999 10.75 12.5999H3.25C3.05109 12.5999 2.86032 12.5262 2.71967 12.3949C2.57902 12.2636 2.5 12.0856 2.5 11.8999V6.99963C2.5 6.81397 2.57902 6.63591 2.71967 6.50463C2.86032 6.37335 3.05109 6.29959 3.25 6.29959H10.75C10.9489 6.29959 11.1397 6.37335 11.2803 6.50463C11.421 6.63591 11.5 6.81397 11.5 6.99963V11.8999Z"
                                          fill="white"
                                        />
                                      </svg>{" "}
                                      Un-ban User
                                    </button>{" "}
                                    <button
                                      // onClick={() => {
                                      // 	setDeleteModal(true);
                                      // }}
                                      className="flex gap-x-1 items-center bg-[#CA0D0D] py-2 px-3 rounded-lg"
                                    >
                                      <svg
                                        width="19"
                                        height="19"
                                        viewBox="0 0 19 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M15.4375 4.35413L14.9469 12.2907C14.8215 14.3184 14.7589 15.3322 14.2506 16.0612C13.9993 16.4216 13.6758 16.7257 13.3006 16.9543C12.5417 17.4166 11.5259 17.4166 9.49422 17.4166C7.45997 17.4166 6.44282 17.4166 5.68341 16.9534C5.30797 16.7245 4.98433 16.4198 4.73312 16.0588C4.22503 15.3287 4.16373 14.3134 4.04115 12.2828L3.5625 4.35413"
                                          stroke="white"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          d="M16.625 4.35413H2.375"
                                          stroke="white"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          d="M12.712 4.35421L12.1716 3.23933C11.8125 2.49875 11.6331 2.12845 11.3234 1.89752C11.2547 1.84629 11.182 1.80072 11.106 1.76126C10.7631 1.58337 10.3516 1.58337 9.52857 1.58337C8.68489 1.58337 8.26301 1.58337 7.91448 1.76872C7.83722 1.8098 7.76351 1.85721 7.69409 1.91047C7.38086 2.15076 7.20589 2.5346 6.85596 3.30229L6.37646 4.35421"
                                          stroke="white"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          d="M7.521 13.0625V8.3125"
                                          stroke="white"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          d="M11.479 13.0625V8.3125"
                                          stroke="white"
                                          stroke-linecap="round"
                                        />
                                      </svg>
                                      Delete User
                                    </button>
                                  </div>
                                </div>

                                {unbanModal && (
                                  <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
                                    <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
                                      <span
                                        className="hidden sm:inline-block sm:h-screen sm:align-middle"
                                        aria-hidden="true"
                                      >
                                        &#8203;
                                      </span>

                                      <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
                                        <div className="flex items-start justify-between ">
                                          <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                                            Confirm Unban Account
                                          </h3>
                                        </div>

                                        <p className="text-left py-5 text-sm text-[#141414]/70">
                                          Are you sure you want to Unban this
                                          user ?
                                        </p>
                                        <div className="flex gap-x-3 items-start justify-between">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setUnbanModal(false);
                                            }}
                                            className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                                          >
                                            No, Cancel
                                          </button>
                                          <button
                                            onClick={() => {
                                              const user_id = item.id;
                                              const banUser = "false";
                                              const data = { user_id, banUser };
                                              unbanHandler(data);
                                            }}
                                            className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                                          >
                                            Yes, Approve
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="sm:col-span-3">
          <div className=" my-3 p-3 bg-gradient-to-b from-[#284C84]  to-[#062F70] rounded-md  ">
            <h2 className="text-[#fff] font-semibold text-base px-4 py-2 text-left  block ">
              Quick Access
            </h2>
            <button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3282 0.0287922L0.407274 4.66911C0.298158 4.70547 0.201879 4.7726 0.130029 4.86241C0.0581799 4.95222 0.0138236 5.06089 0.00230107 5.17532C-0.00743642 5.28588 0.0138567 5.39696 0.0637867 5.49607C0.113717 5.59518 0.190304 5.67841 0.284939 5.73638L6.0769 9.03101L9.34199 14.8272C9.38851 14.9081 9.45338 14.977 9.53136 15.0283C9.60935 15.0796 9.69828 15.1118 9.79101 15.1225C9.88374 15.1332 9.97768 15.1219 10.0653 15.0897C10.1529 15.0575 10.2317 15.0051 10.2954 14.9369C10.361 14.8704 10.4101 14.7895 10.4388 14.7006L15.0791 0.77968C15.1128 0.67518 15.117 0.563408 15.0913 0.456671C15.0655 0.349934 15.0108 0.252375 14.9332 0.174735C14.8555 0.0970955 14.758 0.0423883 14.6512 0.0166382C14.5445 -0.00911194 14.4327 -0.00490642 14.3282 0.0287922ZM9.73009 13.0639L7.2412 8.7104L11.1391 4.81254L10.2954 3.96884L6.3975 7.86671L2.04404 5.37781L13.5689 1.539L9.73009 13.0639Z"
                  fill="white"
                />
              </svg>{" "}
              Fund an Account
            </button>
            <button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_761_4964)">
                  <path
                    d="M5.33317 7.33333C5.15636 7.33333 4.98679 7.40357 4.86177 7.52859C4.73674 7.65361 4.6665 7.82318 4.6665 7.99999C4.6665 8.17681 4.73674 8.34637 4.86177 8.4714C4.98679 8.59642 5.15636 8.66666 5.33317 8.66666H10.6665C10.8433 8.66666 11.0129 8.59642 11.1379 8.4714C11.2629 8.34637 11.3332 8.17681 11.3332 7.99999C11.3332 7.82318 11.2629 7.65361 11.1379 7.52859C11.0129 7.40357 10.8433 7.33333 10.6665 7.33333H5.33317Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.3332 8C15.3332 12.05 12.0498 15.3333 7.99984 15.3333C3.94984 15.3333 0.666504 12.05 0.666504 8C0.666504 3.95 3.94984 0.666664 7.99984 0.666664C12.0498 0.666664 15.3332 3.95 15.3332 8ZM13.9998 8C13.9998 8.78793 13.8446 9.56814 13.5431 10.2961C13.2416 11.0241 12.7996 11.6855 12.2425 12.2426C11.6853 12.7998 11.0239 13.2417 10.2959 13.5433C9.56798 13.8448 8.78777 14 7.99984 14C7.21191 14 6.43169 13.8448 5.70374 13.5433C4.97578 13.2417 4.31435 12.7998 3.7572 12.2426C3.20004 11.6855 2.75809 11.0241 2.45656 10.2961C2.15503 9.56814 1.99984 8.78793 1.99984 8C1.99984 6.4087 2.63198 4.88258 3.7572 3.75736C4.88241 2.63214 6.40854 2 7.99984 2C9.59114 2 11.1173 2.63214 12.2425 3.75736C13.3677 4.88258 13.9998 6.4087 13.9998 8Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_761_4964">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Debit an Account
            </button>
            <button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.49994 0.66214C8.87734 0.66214 10.2238 1.07059 11.3691 1.83583C12.5144 2.60108 13.407 3.68875 13.9341 4.96131C14.4612 6.23387 14.5991 7.63415 14.3304 8.98509C14.0617 10.336 13.3984 11.5769 12.4244 12.5509C11.4505 13.5249 10.2095 14.1882 8.8586 14.4569C7.50766 14.7256 6.10738 14.5877 4.83482 14.0606C3.56226 13.5335 2.47459 12.6408 1.70934 11.4956C0.944098 10.3503 0.535649 9.00383 0.535649 7.62643C0.533441 5.6314 1.30103 3.71244 2.67851 2.26928"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.535645 2.80499L2.6785 2.26928L3.21422 4.41214M7.49993 3.87642V8.16214L10.2856 9.55499"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              Banned Accounts
            </button>
          </div>
          <div class="bg-white  rounded-lg mb-4 p-4 sm:p-6  ">
            <div class="flex items-center justify-between mb-4">
              <h3 class="text-xl font-bold leading-none text-gray-900">
                Latest Customers
              </h3>
            </div>
            <div class="flow-root">
              <ul class="divide-y divide-gray-200">
                {user.slice(0, 6).map((item, index) => (
                  <li key={index} className="py-3 sm:py-3">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src={item.image}
                          alt="Neil"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-semibold text-gray-900 truncate">
                          {item.full_name}
                        </p>
                        <p className="text-sm text-gray-500 truncate cursor-pointer">
                          {item.email}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {unbanSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          B
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Account Unbanned Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully unbanned this user.
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUnbanSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BannedAccounts;
