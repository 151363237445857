import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import PageLoading from "../../../components/PageLoading";
import {
  useBanUserMutation,
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from "../../../Slices/userApsSlice";
import Fund from "../../../components/Fund";

function Index() {
  const [user, setUser] = useState([]);
  const [userId, setUserId] = useState([]);
  const [banModal, setBanModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unbanModal, setUnbanModal] = useState(false);
  const [deleteSucess, setDeleteSucess] = useState(false);
  const [banSucess, setBanSucess] = useState(false);
  const [unbanSucess, setUnbanSucess] = useState(false);
  const [fundModal, setFundModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { data, isLoading, refetch, error } = useGetAllUsersQuery();

  useEffect(() => {
    if (data) {
      setUser(data.users);
    }
  }, [data]);

  const filteredAccounts = user.filter((item) =>
    item.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [banuser] = useBanUserMutation();
  const banHandler = async (bandata) => {
    try {
      await banuser(bandata);
      setBanModal(false);
      setBanSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const unbanHandler = async (bandata) => {
    try {
      await banuser(bandata);
      setUnbanModal(false);
      setUnbanSucess(true);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [deleteuser] = useDeleteUserMutation();
  const deleteHandler = async (deletedata) => {
    try {
      await deleteuser(deletedata);
      setDeleteModal(false);
      setDeleteSucess(true);
      Navigate();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="px-5">
      <div className="flex justify-between gap-x-2  my-5 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            Home{" "}
            <span className=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Users
          </h2>
        </div>

        {/* <Search /> */}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4 mb-10">
        <div className="sm:col-span-9">
          <div className="flex justify-between items-center  gap-x-2  mb-5 ">
            <div className="">
              <h2 className="text-[#0C0C0C] font-extrabold text-2xl py-1 text-left  block ">
                Accounts
              </h2>
              <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
                This is a list of all users.
              </h2>
            </div>
            <div className="flex items-center gap-4">
              <div className="relative ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg
                    className="w-4 h-4 text-gray-400"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  className="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
                  placeholder="Search Users"
                  autoComplete="off"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-left  block ">
                Total No users ({user.length})
              </h2>
            </div>
          </div>

          <section className="container   rounded mx-auto">
            <div className="flex flex-col">
              <div className=" overflow-x-auto  h-[36rem] scrollbar-hide ">
                <div className="inline-block min-w-full py-2 align-middle ">
                  <div className="overflow-hidden md:rounded-lg ">
                    <div className="min-w-full ">
                      {isLoading ? (
                        <div className="pb-5">
                          <PageLoading />
                        </div>
                      ) : error ? (
                        <div className="pt-5">
                          <Loading />
                        </div>
                      ) : (
                        <>
                          {filteredAccounts.map((item) => (
                            <div
                              key={item.id}
                              className="my-2 rounded-lg flex items-center justify-between bg-[#F9FAFB]"
                            >
                              <div className="flex">
                                <div className="px-5 text-sm font-medium text-gray-700 bg-[#E7E7E7] whitespace-nowrap">
                                  <div className="py-3 flex flex-wrap content-center justify-center">
                                    <svg
                                      width="35"
                                      height="35"
                                      viewBox="0 0 35 35"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M17.5 0C7.84 0 0 7.84 0 17.5C0 27.16 7.84 35 17.5 35C27.16 35 35 27.16 35 17.5C35 7.84 27.16 0 17.5 0ZM17.5 7C20.8775 7 23.625 9.7475 23.625 13.125C23.625 16.5025 20.8775 19.25 17.5 19.25C14.1225 19.25 11.375 16.5025 11.375 13.125C11.375 9.7475 14.1225 7 17.5 7ZM17.5 31.5C13.9475 31.5 9.7475 30.065 6.755 26.46C9.82031 24.055 13.6038 22.7479 17.5 22.7479C21.3962 22.7479 25.1797 24.055 28.245 26.46C25.2525 30.065 21.0525 31.5 17.5 31.5Z"
                                        fill="white"
                                      />
                                    </svg>
                                  </div>
                                </div>

                                <div className="px-3 py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
                                  {item.full_name}
                                </div>
                              </div>
                              <div className="px-5  text-sm text-gray-500 font-thin  whitespace-nowrap">
                                Total Transactions ({item.total_transactions})
                              </div>
                              <div className="px-5  text-sm text-gray-500 font-thin  whitespace-nowrap">
                                Wallet Balance (NGN {item.walletBalance})
                              </div>

                              <div className="px-3 text-sm text-[#fff] whitespace-nowrap ">
                                <div className="flex items-center justify-end">
                                  <Link
                                    to={`${item.id}/details`}
                                    className="bg-[#0F3776] py-2 px-6 rounded-lg"
                                  >
                                    View Info
                                  </Link>
                                  <div className=" inline-block group ml-2">
                                    <button
                                      type="submit"
                                      className="px-0.5 ml-1 text-xs font-medium text-white   rounded-md focus:outline-none "
                                    >
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g clip-path="url(#clip0_14_1968)">
                                          <path
                                            d="M7.02002 8.9991C7.02002 9.25923 7.07126 9.51682 7.17081 9.75716C7.27036 9.99749 7.41627 10.2159 7.60021 10.3998C7.78416 10.5838 8.00253 10.7297 8.24286 10.8292C8.4832 10.9288 8.74078 10.98 9.00092 10.98C9.26105 10.98 9.51864 10.9288 9.75898 10.8292C9.99931 10.7297 10.2177 10.5838 10.4016 10.3998C10.5856 10.2159 10.7315 9.99749 10.831 9.75716C10.9306 9.51682 10.9818 9.25923 10.9818 8.9991C10.9817 8.47373 10.7729 7.96993 10.4013 7.59852C10.0297 7.22712 9.52584 7.01853 9.00047 7.01865C8.4751 7.01877 7.9713 7.22758 7.59989 7.59916C7.22849 7.97074 7.0199 8.47463 7.02002 9L7.02002 8.9991ZM7.02002 15.2991C7.02002 15.5592 7.07126 15.8168 7.17081 16.0572C7.27036 16.2975 7.41627 16.5159 7.60021 16.6998C7.78416 16.8838 8.00253 17.0297 8.24286 17.1292C8.4832 17.2288 8.74078 17.28 9.00092 17.28C9.26106 17.28 9.51864 17.2288 9.75898 17.1292C9.99931 17.0297 10.2177 16.8838 10.4016 16.6998C10.5856 16.5159 10.7315 16.2975 10.831 16.0572C10.9306 15.8168 10.9818 15.5592 10.9818 15.2991C10.9817 14.7737 10.7729 14.2699 10.4013 13.8985C10.0297 13.5271 9.52584 13.3185 9.00047 13.3186C8.4751 13.3188 7.9713 13.5276 7.59989 13.8992C7.22849 14.2707 7.0199 14.7737 7.02002 15.2991ZM7.02002 2.6991C7.02002 2.95923 7.07126 3.21682 7.17081 3.45716C7.27036 3.69749 7.41627 3.91586 7.60021 4.09981C7.78415 4.28375 8.00253 4.42966 8.24286 4.52921C8.48319 4.62876 8.74078 4.68 9.00092 4.68C9.26105 4.68 9.51864 4.62876 9.75898 4.52921C9.99931 4.42966 10.2177 4.28375 10.4016 4.09981C10.5856 3.91586 10.7315 3.69749 10.831 3.45716C10.9306 3.21682 10.9818 2.95923 10.9818 2.6991C10.9817 2.17373 10.7729 1.66993 10.4013 1.29852C10.0297 0.927116 9.52584 0.718529 9.00047 0.718649C8.4751 0.718767 7.9713 0.927584 7.59989 1.29916C7.22849 1.67073 7.0199 2.17373 7.02002 2.6991Z"
                                            fill="#B9B9B9"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_14_1968">
                                            <rect
                                              width="18"
                                              height="18"
                                              fill="white"
                                              transform="translate(0 18) rotate(-90)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </button>
                                    {/* <!-- Dropdown menu --> */}
                                    <div className="absolute  z-[100] w-32 p-2 hidden group-hover:block right-30 bg-[#FFFFFF] rounded-md shadow-xl ">
                                      <button
                                        onClick={() => {
                                          setUserId(item.id);
                                          setFundModal(true);
                                        }}
                                        className="w-full text-left block px-4 py-2 text-xs text-[#0C0C0C] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                      >
                                        Fund User
                                      </button>
                                      <button
                                        onClick={() => {
                                          setUserId(item.id);
                                          setBanModal(true);
                                        }}
                                        className="w-full text-left block px-4 py-2 text-xs text-[#0C0C0C] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                      >
                                        Ban User
                                      </button>
                                      <button
                                        onClick={() => {
                                          setUserId(item.id);
                                          setDeleteModal(true);
                                        }}
                                        className="w-full text-left block px-4 py-2 text-xs text-[#CA0D0D] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                                      >
                                        Delete User
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="sm:col-span-3">
          <div className=" my-3 p-3 bg-gradient-to-b from-[#284C84]  to-[#062F70] rounded-md  ">
            <h2 className="text-[#fff] font-semibold text-base px-4 py-2 text-left  block ">
              Quick Access
            </h2>
            <button
              onClick={(e) => {
                e.preventDefault();
                setFundModal(true);
              }}
              className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.3282 0.0287922L0.407274 4.66911C0.298158 4.70547 0.201879 4.7726 0.130029 4.86241C0.0581799 4.95222 0.0138236 5.06089 0.00230107 5.17532C-0.00743642 5.28588 0.0138567 5.39696 0.0637867 5.49607C0.113717 5.59518 0.190304 5.67841 0.284939 5.73638L6.0769 9.03101L9.34199 14.8272C9.38851 14.9081 9.45338 14.977 9.53136 15.0283C9.60935 15.0796 9.69828 15.1118 9.79101 15.1225C9.88374 15.1332 9.97768 15.1219 10.0653 15.0897C10.1529 15.0575 10.2317 15.0051 10.2954 14.9369C10.361 14.8704 10.4101 14.7895 10.4388 14.7006L15.0791 0.77968C15.1128 0.67518 15.117 0.563408 15.0913 0.456671C15.0655 0.349934 15.0108 0.252375 14.9332 0.174735C14.8555 0.0970955 14.758 0.0423883 14.6512 0.0166382C14.5445 -0.00911194 14.4327 -0.00490642 14.3282 0.0287922ZM9.73009 13.0639L7.2412 8.7104L11.1391 4.81254L10.2954 3.96884L6.3975 7.86671L2.04404 5.37781L13.5689 1.539L9.73009 13.0639Z"
                  fill="white"
                />
              </svg>{" "}
              Fund an Account
            </button>
            <button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_761_4964)">
                  <path
                    d="M5.33317 7.33333C5.15636 7.33333 4.98679 7.40357 4.86177 7.52859C4.73674 7.65361 4.6665 7.82318 4.6665 7.99999C4.6665 8.17681 4.73674 8.34637 4.86177 8.4714C4.98679 8.59642 5.15636 8.66666 5.33317 8.66666H10.6665C10.8433 8.66666 11.0129 8.59642 11.1379 8.4714C11.2629 8.34637 11.3332 8.17681 11.3332 7.99999C11.3332 7.82318 11.2629 7.65361 11.1379 7.52859C11.0129 7.40357 10.8433 7.33333 10.6665 7.33333H5.33317Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.3332 8C15.3332 12.05 12.0498 15.3333 7.99984 15.3333C3.94984 15.3333 0.666504 12.05 0.666504 8C0.666504 3.95 3.94984 0.666664 7.99984 0.666664C12.0498 0.666664 15.3332 3.95 15.3332 8ZM13.9998 8C13.9998 8.78793 13.8446 9.56814 13.5431 10.2961C13.2416 11.0241 12.7996 11.6855 12.2425 12.2426C11.6853 12.7998 11.0239 13.2417 10.2959 13.5433C9.56798 13.8448 8.78777 14 7.99984 14C7.21191 14 6.43169 13.8448 5.70374 13.5433C4.97578 13.2417 4.31435 12.7998 3.7572 12.2426C3.20004 11.6855 2.75809 11.0241 2.45656 10.2961C2.15503 9.56814 1.99984 8.78793 1.99984 8C1.99984 6.4087 2.63198 4.88258 3.7572 3.75736C4.88241 2.63214 6.40854 2 7.99984 2C9.59114 2 11.1173 2.63214 12.2425 3.75736C13.3677 4.88258 13.9998 6.4087 13.9998 8Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_761_4964">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Debit an Account
            </button>
            <Link
              to="banned"
              className="flex gap-x-2 w-full text-left px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
            >
              <svg
                width="15"
                height="16"
                viewBox="0 0 15 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.49994 0.66214C8.87734 0.66214 10.2238 1.07059 11.3691 1.83583C12.5144 2.60108 13.407 3.68875 13.9341 4.96131C14.4612 6.23387 14.5991 7.63415 14.3304 8.98509C14.0617 10.336 13.3984 11.5769 12.4244 12.5509C11.4505 13.5249 10.2095 14.1882 8.8586 14.4569C7.50766 14.7256 6.10738 14.5877 4.83482 14.0606C3.56226 13.5335 2.47459 12.6408 1.70934 11.4956C0.944098 10.3503 0.535649 9.00383 0.535649 7.62643C0.533441 5.6314 1.30103 3.71244 2.67851 2.26928"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M0.535645 2.80499L2.6785 2.26928L3.21422 4.41214M7.49993 3.87642V8.16214L10.2856 9.55499"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              Banned Accounts
            </Link>
          </div>
          <div className="bg-white  rounded-lg mb-4 p-4 sm:p-6 ">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-bold leading-none text-gray-900">
                Latest Customers
              </h3>
            </div>
            <div className="flow-root ">
              <ul className="divide-y divide-gray-200 ">
                {user.slice(0, 6).map((item, index) => (
                  <li key={index} className="py-3 sm:py-3">
                    <div className="flex items-center space-x-4">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src={item.image}
                          alt="Neil"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-semibold text-gray-900 truncate">
                          {item.full_name}
                        </p>
                        <p className="text-sm text-gray-500 truncate cursor-pointer">
                          {item.email}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {banModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Account Ban
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to ban this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setBanModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const user_id = userId;
                    const banUser = "true";
                    const data = { user_id, banUser };
                    banHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {banSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Account Banned Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Banned this user
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setBanSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {unbanModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Unban Account
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to Unban this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUnbanModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const user_id = userId;
                    const banUser = "false";
                    const data = { user_id, banUser };
                    unbanHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                >
                  Yes, Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {unbanSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          B
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Account Unbanned Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully unbanned this user.
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUnbanSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Account Delete
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to delete this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const data = userId;
                    deleteHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Account Deleted Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Deleted this user
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {fundModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setFundModal(false);
                  }}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm  ml-auto inline-flex items-center "
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Fund User
              </h3>

              <Fund setFundModal={setFundModal} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Index;
