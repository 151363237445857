import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import PageLoading from "../../components/PageLoading";
import Spinner from "../../components/Spinner";
import { useGetTransactionsWithTransactionTypensactionsQuery } from "../../Slices/transactionApsSlice";
import { BASE_URL } from "../../utils";
import FormattedDate from "../../components/FormattedDate";
import { useForm } from "react-hook-form";
import Fund from "../../components/Fund";
import Search from "../../components/Search";

const DeclineGiftForm = ({ id, setDeclineGiftCardModal }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "errorimage"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlwff8kwh/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      setValue("image", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const declineGiftCardHandler = async (data) => {
    setLoading(true);

    try {
      const res = await axios.post(
        `${BASE_URL}admin/declinedGiftCardStatus/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setLoading(false);
      const response = res.data.message;
      toast.success(response);
      setDeclineGiftCardModal(false);
      window.location.reload(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(declineGiftCardHandler)(e);
        reset();
      }}
      className="overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC] text-left"
    >
      <div className="px-4 pb-4  gap-x-2">
        <div className="w-full">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Enter Decline Message
          </label>

          <input
            type="text"
            required
            {...register("message")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full mt-5">
          <label htmlFor="SellingRate" className="block text-xs text-gray-500 ">
            Add Image
          </label>
          <input type="text" {...register("image")} className="hidden " />
          <div className="flex">
            <label
              class="cursor-pointer inline-flex items-center  -mr-24 px-3 py-2 bg-[#E4E4E4] text-gray-500 rounded-l-2xl font-semibold text-xs  hover:bg-gray-300 active:bg-[#E4E4E4] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 relative  w-[6rem] z-10"
              for="thumbnail"
            >
              Choose File
            </label>
            <input
              id="thumbnail"
              type="file"
              onChange={handleUpload}
              class="py-3 px-8 border-[0.5px] bg-[#F6F8F8]/50 border-[#757E7E]/20 rounded-2xl w-full focus:outline-none text-xs"
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={loadingUpload}
          className="font-semibold w-[15rem] h-[2.5rem] mt-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {isLoading ? <Spinner /> : " Decline Gift Card"}
        </button>
      </div>
    </form>
  );
};

const ApproveGiftForm = ({ id, setApproveGiftCardModal, refetch }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [isLoading, setLoading] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();
  const approveGiftCardHandler = async (data) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${BASE_URL}admin/updateGiftcardStatus/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setLoading(false);
      const message = res.data.message;
      toast.success(message);
      setApproveGiftCardModal(false);
      refetch();
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  // Approve GiftCard
  useEffect(() => {
    setValue("status", "approved"); // Set default value for the 'age' field
  }, [setValue, id]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(approveGiftCardHandler)(e);
        reset();
      }}
      className="overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC]"
    >
      <div className="px-4 pb-4 flex gap-x-2">
        <div className="w-full hidden">
          <label htmlFor="Status" className="block text-xs text-gray-500 ">
            Status
          </label>

          <input
            type="text"
            required
            {...register("status")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>
        <div className="w-full">
          <label htmlFor="Status" className="block text-xs text-gray-500 ">
            Enter Approve Message
          </label>

          <input
            type="text"
            required
            {...register("message")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <button
          type="submit"
          className="font-semibold w-[5rem] h-[2.5rem] mt-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {isLoading ? <Spinner /> : " Submit"}
        </button>
      </div>
    </form>
  );
};

const DeclineCryptoForm = ({ id, setDeclineCryptoModal }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, setValue, reset } = useForm();
  const [loadingUpload, setLoadingUpload] = useState(false);
  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "errorimage"); // Replace with your Cloudinary upload preset
    setLoadingUpload(true);
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dlwff8kwh/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );
      const data = await response.json();
      setValue("image", data.secure_url);
      setLoadingUpload(false);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const declineCryptoHandler = async (data) => {
    setLoading(true);
    try {
      const res = await axios.put(
        `${BASE_URL}admin/declinedCryptoStatus/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setLoading(false);
      const message = res.data.message;
      toast.success(message);
      setDeclineCryptoModal(false);
      window.location.reload(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(declineCryptoHandler)(e);
        reset();
      }}
      className="overflow-hidden transition-all duration-500 peer-checked:max-h-96 bg-[#FCFCFC] text-left"
    >
      <div className="px-4 py-4 gap-x-2">
        <div className="w-full">
          <label htmlFor="Message" className="block text-xs text-gray-500 ">
            Enter Decline Message
          </label>

          <input
            type="text"
            required
            {...register("message")}
            className="block text-xs mt-2 w-full placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
          />
        </div>

        <div className="w-full mt-5">
          <label
            htmlFor="AddImage"
            className="block pb-2 text-xs text-gray-500 "
          >
            Add Image
          </label>
          <input type="text" {...register("image")} className="hidden " />
          <div className="flex">
            <label
              class="cursor-pointer inline-flex items-center  -mr-24 px-3 py-2 bg-[#E4E4E4] text-gray-500 rounded-l-2xl font-semibold text-xs  hover:bg-gray-300 active:bg-[#E4E4E4] focus:outline-none disabled:opacity-25 transition ease-in-out duration-150 relative  w-[6rem] z-10"
              for="thumbnail"
            >
              Choose File
            </label>
            <input
              id="thumbnail"
              type="file"
              onChange={handleUpload}
              class="py-3 px-8 border-[0.5px] bg-[#F6F8F8]/50 border-[#757E7E]/20 rounded-2xl w-full focus:outline-none text-xs"
            />
          </div>
        </div>

        <button
          type="submit"
          disabled={loadingUpload}
          className="font-semibold w-[15rem] h-[2.5rem] mt-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#284C84]  px-3 py-2.5 rounded-lg "
        >
          {isLoading ? <Spinner /> : "Decline"}
        </button>
      </div>
    </form>
  );
};
function Transactions() {
  const [openTab, setOpenTab] = useState(1);
  const [modal, setModal] = useState(false);
  const [billsTransaction, setBillsTransaction] = useState([]);
  const [cryptoTransaction, setCryptoTransaction] = useState([]);
  const [giftcardTransaction, setGiftcardTransaction] = useState([]);
  const [transactionModal, setTransactionModal] = useState(false);
  const [singleTrans, setSingleTrans] = useState([]);
  const [cryptoModal, setCryptoModal] = useState(false);
  const [giftCardModal, setGiftCardModal] = useState(false);
  const [approveGiftCardModal, setApproveGiftCardModal] = useState(false);
  const [singleCrypto, setSingleCrypto] = useState([]);
  const [singleGiftCard, setSingleGiftCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data, isLoading, refetch, error } =
    useGetTransactionsWithTransactionTypensactionsQuery();
  const [declineId, setDeclineId] = useState("");
  const [approveId, setApproveId] = useState("");
  const [declineCryptoModal, setDeclineCryptoModal] = useState(false);
  const [declineGiftCardModal, setDeclineGiftCardModal] = useState(false);
  const [fundModal, setFundModal] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (data) {
      setBillsTransaction(data.bills_and_utility);
      setCryptoTransaction(data.crypto);
      setGiftcardTransaction(data.giftcard);
    }
  }, [data]);

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;

  const getHandler = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}admin/getSingleExpnseTransaction/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      const response = res.data.expenseTransaction;
      setSingleTrans(response);
      setTransactionModal(true);
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  // Get Single Crypto Transaction
  const getCryptoHandler = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}admin/getSingleCryptoTransaction/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      const resp = await res.data.cryptoTransaction;
      setSingleCrypto(resp);
      setCryptoModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Get Single Gift Card Transaction
  const getGiftCardHandler = async (id) => {
    try {
      const res = await axios.get(
        `${BASE_URL}admin/getSingleGiftcardTransaction/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      const respi = await res.data.giftcardTransaction;
      console.log(respi);
      setSingleGiftCard(respi);
      setGiftCardModal(true);
    } catch (err) {
      toast.error(err?.data?.message);
    }
  };

  // Approve Crypto
  const approveHandler = async (id) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `${BASE_URL}admin/updateCryptoStatus/${id}`,
        { status: "approve" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      setLoading(false);
      toast.success(res.message);
      refetch();
      setCryptoModal(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.message || err?.data.message);
    }
  };

  // Approve GiftCard
  // const approveGiftCardHandler = async (id) => {
  //   try {
  //     setLoading(true);
  //     const res = await axios.put(
  //       `${BASE_URL}admin/updateGiftcardStatus/${id}`,
  //       { status: "approve" },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "content-type": "application/json",
  //           accept: "application/json",
  //         },
  //       }
  //     );
  //     setLoading(false);
  //     toast.success(res.message);
  //     refetch();
  //     setGiftCardModal(false);
  //   } catch (err) {
  //     setLoading(false);
  //     toast.error(err?.message || err?.data.message);
  //   }
  // };

  return (
    <div className="px-10">
      <div className="flex justify-between items-center  gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            <Link to="/dashboard/index">Home</Link>
            <span className=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            <Link to="/dashboard/merchant">Transactions</Link>
          </h2>
        </div>

        <Search />
      </div>
      <div className="flex justify-between items-center  gap-x-2  ">
        <div>
          <h2 className="text-[#000]   text-lg font-extrabold ">
            Transactions
          </h2>
          <h2 className="text-[#71717A]  text-xs  text-left   ">
            This is a list of latest transactions.
          </h2>
        </div>

        <div className="flex gap-x-3 h-10">
          <button
            onClick={(e) => {
              e.preventDefault();
              setModal(true);
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#264A83]  px-5 py-2 rounded-lg "
          >
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.25 16.875C2.41062 17.3558 2.59901 17.8235 2.8132 18.2758M4.64115 21.0896C4.99678 21.5029 5.37862 21.8921 5.78404 22.2541M10.125 24.75C9.62127 24.5877 9.13164 24.3927 8.65846 24.1675"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.5 15.1875C14.432 15.1875 15.1875 14.432 15.1875 13.5C15.1875 12.568 14.432 11.8125 13.5 11.8125C12.568 11.8125 11.8125 12.568 11.8125 13.5M13.5 15.1875C12.568 15.1875 11.8125 14.432 11.8125 13.5M13.5 15.1875V18M11.8125 13.5H6.75"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M13.5 24.75C19.7131 24.75 24.75 19.7131 24.75 13.5C24.75 7.28679 19.7131 2.25 13.5 2.25C7.28679 2.25 2.25 7.28679 2.25 13.5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>{" "}
            Pending Transactions
            {billsTransaction.filter((item) => item.status === "pending")
              .length > 0 && (
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10.5C7.76142 10.5 10 8.26142 10 5.5C10 2.73858 7.76142 0.5 5 0.5C2.23858 0.5 0 2.73858 0 5.5C0 8.26142 2.23858 10.5 5 10.5Z"
                  fill="#ED5846"
                />
              </svg>
            )}
            {cryptoTransaction.filter((item) => item.status === "pending")
              .length > 0 && (
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10.5C7.76142 10.5 10 8.26142 10 5.5C10 2.73858 7.76142 0.5 5 0.5C2.23858 0.5 0 2.73858 0 5.5C0 8.26142 2.23858 10.5 5 10.5Z"
                  fill="#ED5846"
                />
              </svg>
            )}
            {giftcardTransaction.filter((item) => item.status === "pending")
              .length > 0 && (
              <svg
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10.5C7.76142 10.5 10 8.26142 10 5.5C10 2.73858 7.76142 0.5 5 0.5C2.23858 0.5 0 2.73858 0 5.5C0 8.26142 2.23858 10.5 5 10.5Z"
                  fill="#ED5846"
                />
              </svg>
            )}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setFundModal(true);
            }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-5 py-2 rounded-lg "
          >
            <svg
              width="31"
              height="32"
              viewBox="0 0 31 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.05066 16C9.05066 15.7431 9.15272 15.4967 9.3344 15.315C9.51608 15.1333 9.76248 15.0312 10.0194 15.0312H14.5312V10.5195C14.5312 10.2625 14.6333 10.0161 14.8149 9.83445C14.9966 9.65277 15.243 9.5507 15.5 9.5507C15.7569 9.5507 16.0033 9.65277 16.185 9.83445C16.3666 10.0161 16.4687 10.2625 16.4687 10.5195V15.0312H20.9805C21.2374 15.0312 21.4838 15.1333 21.6655 15.315C21.8472 15.4967 21.9492 15.7431 21.9492 16C21.9492 16.2569 21.8472 16.5033 21.6655 16.685C21.4838 16.8667 21.2374 16.9687 20.9805 16.9687H16.4687V21.4805C16.4687 21.7375 16.3666 21.9839 16.185 22.1655C16.0033 22.3472 15.7569 22.4493 15.5 22.4493C15.243 22.4493 14.9966 22.3472 14.8149 22.1655C14.6333 21.9839 14.5312 21.7375 14.5312 21.4805V16.9687H10.0194C9.76248 16.9687 9.51608 16.8667 9.3344 16.685C9.15272 16.5033 9.05066 16.2569 9.05066 16Z"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.45119 5.36829C13.4715 4.92261 17.5287 4.92261 21.5489 5.36829C23.9088 5.63179 25.814 7.49049 26.0904 9.86329C26.5683 13.9411 26.5683 18.0602 26.0904 22.138C25.8127 24.5108 23.9075 26.3682 21.5489 26.633C17.5287 27.0787 13.4715 27.0787 9.45119 26.633C7.09131 26.3682 5.1861 24.5108 4.90969 22.138C4.43277 18.0603 4.43277 13.941 4.90969 9.86329C5.1861 7.49049 7.0926 5.63179 9.45119 5.36829ZM21.3345 7.29287C17.4568 6.86305 13.5434 6.86305 9.6656 7.29287C8.94775 7.37251 8.27771 7.69183 7.76372 8.19925C7.24974 8.70667 6.92183 9.37256 6.83298 10.0893C6.37359 14.0172 6.37359 17.9853 6.83298 21.9132C6.9221 22.6298 7.25013 23.2954 7.76409 23.8025C8.27805 24.3097 8.94794 24.6288 9.6656 24.7084C13.5109 25.1372 17.4892 25.1372 21.3345 24.7084C22.0519 24.6286 22.7215 24.3093 23.2352 23.8022C23.7489 23.295 24.0768 22.6296 24.1659 21.9132C24.6252 17.9853 24.6252 14.0172 24.1659 10.0893C24.0765 9.37322 23.7486 8.70808 23.2349 8.2012C22.7212 7.69432 22.0518 7.37525 21.3345 7.29545V7.29287Z"
                fill="white"
              />
            </svg>
            fund a User
          </button>
          <button
            // onClick={(e) => {
            // 	e.preventDefault();
            // 	navigate('/edit_address');
            // }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#D3B61F]  px-5 py-2 rounded-lg "
          >
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6667 11.5H7.33337"
                stroke="white"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M11 20.6667C16.0627 20.6667 20.1667 16.5626 20.1667 11.5C20.1667 6.43738 16.0627 2.33333 11 2.33333C5.93743 2.33333 1.83337 6.43738 1.83337 11.5C1.83337 16.5626 5.93743 20.6667 11 20.6667Z"
                stroke="white"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </svg>
            Debit a user
          </button>
        </div>
      </div>

      <div className="mt-6 md:flex md:items-center md:justify-between pr-5">
        <div className="flex   rtl:flex-row-reverse   w-screen sm:w-min overflow-x-auto scrollbar-hide inset-x-0 mt-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            className={` px-2 text-xs ${
              openTab === 1
                ? "border-b-4 border-[#264A83] py-1 font-extrabold text-gray-900 "
                : "text-[#264A83]/60"
            } transition-colors duration-200    hover:bg-gray-100`}
          >
            <span className="w-[6.5rem]   flex justify-center text-center">
              Bills and Utility
            </span>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
            className={` px-2 text-xs ${
              openTab === 2
                ? "border-b-4 border-[#264A83] py-1 font-extrabold text-gray-900 "
                : "text-[#264A83]/60"
            } transition-colors duration-200    hover:bg-gray-100`}
          >
            <span className="w-[4.5rem]  flex justify-center text-center">
              Crypto
            </span>
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(3);
            }}
            className={`w-[3rem]   text-xs font-medium ${
              openTab === 3
                ? "border-b-4 border-[#264A83] py-1 font-bold text-gray-900 "
                : "text-[#264A83]/60"
            } transition-colors duration-200    hover:bg-gray-100`}
          >
            <span className=" text-center">Giftcard</span>
          </button>
        </div>

        <div className="flex justify-between items-center  gap-x-1  ">
          <svg
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.5094 13.681C24.5094 7.69789 19.6591 2.84764 13.6761 2.84764C7.69302 2.84764 2.84277 7.69789 2.84277 13.681C2.84277 19.664 7.69302 24.5143 13.6761 24.5143C19.6591 24.5143 24.5094 19.664 24.5094 13.681Z"
              stroke="#141B34"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M9.34277 14.2226H18.0093"
              stroke="#141B34"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M11.5088 18.0143H15.8421"
              stroke="#141B34"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
            <path
              d="M8.25879 10.431H19.0921"
              stroke="#141B34"
              stroke-width="1.5"
              stroke-linejoin="round"
            />
          </svg>
          Filter:
          <div className="w-[4rem] ">
            <div className="relative">
              <select className="py-2 my-1 block appearance-none cursor-pointer text-[#000] bg-[#FFF] rounded-sm w-light focus:outline-none  font-thin text-sm">
                <option selected>Newest</option>
                <option value="male">Monthly</option>
                <option value="female">Yearly</option>
              </select>
            </div>
          </div>
          <button
            // onClick={(e) => {
            // 	e.preventDefault();
            // 	navigate('/edit_address');
            // }}
            className="flex items-center gap-x-2 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#141B34]   px-5 py-2 rounded-lg "
          >
            Print
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.85396 18C5.73084 18 4.66928 18 3.91349 17.5468C3.41953 17.2506 3.02158 16.8271 2.76475 16.3242C2.37179 15.5547 2.47742 14.5373 2.68868 12.5025C2.86503 10.8039 2.95321 9.95455 3.38684 9.33081C3.67153 8.92129 4.05659 8.58564 4.50797 8.35353C5.19548 8 6.08164 8 7.85396 8H17.146C18.9184 8 19.8045 8 20.492 8.35353C20.9434 8.58564 21.3285 8.92129 21.6132 9.33081C22.0468 9.95455 22.135 10.8039 22.3113 12.5025C22.5226 14.5373 22.6282 15.5547 22.2352 16.3242C21.9784 16.8271 21.5805 17.2506 21.0865 17.5468C20.3307 18 19.2692 18 17.146 18"
                stroke="currentColor"
                stroke-width="1.5"
              />
              <path
                d="M17.5 8V6C17.5 4.11438 17.5 3.17157 16.9142 2.58579C16.3284 2 15.3856 2 13.5 2H11.5C9.61438 2 8.67157 2 8.08579 2.58579C7.5 3.17157 7.5 4.11438 7.5 6V8"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M14.4887 16H10.5113C9.82602 16 9.48337 16 9.19183 16.1089C8.80311 16.254 8.47026 16.536 8.2462 16.9099C8.07815 17.1904 7.99505 17.5511 7.82884 18.2724C7.56913 19.3995 7.43928 19.963 7.52759 20.4149C7.64535 21.0174 8.01237 21.5274 8.52252 21.7974C8.90513 22 9.44052 22 10.5113 22H14.4887C15.5595 22 16.0949 22 16.4775 21.7974C16.9876 21.5274 17.3547 21.0174 17.4724 20.4149C17.5607 19.963 17.4309 19.3995 17.1712 18.2724C17.005 17.5511 16.9218 17.1904 16.7538 16.9099C16.5297 16.536 16.1969 16.254 15.8082 16.1089C15.5166 16 15.174 16 14.4887 16Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M18.5 12H18.5093"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Table+++++++++++++++++++++++++++++++ */}

      <div className={openTab === 1 ? "block" : "hidden"}>
        <div className="flex flex-col my-8">
          <div className="overflow-x-auto rounded-lg h-[32rem] scrollbar-hide">
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden sm:rounded-lg">
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoading />
                  </div>
                ) : error ? (
                  <div className="pt-5">
                    <Loading />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          ACCOUNT NAME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          DATE & TIME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          AMOUNT
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          STATUS
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          TYPE
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          DESCRIPTION
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white">
                      {billsTransaction.map((item, i) => (
                        <tr
                          key={item.id}
                          className={i % 2 === 0 ? "" : "bg-gray-50"}
                        >
                          <td
                            onClick={() => getHandler(item.id)}
                            className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                fill="#141B34"
                              />
                              <path
                                d="M7.33337 6.41667H14.6667"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.33337 10.0833H11"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <span className="font-semibold">
                              {item.user === null ? "" : item.user.full_name}
                            </span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                            <FormattedDate date={item?.created_at} />
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            ₦ {item.amount.toLocaleString()}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.status === "completed" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Completed
                                </span>
                              </span>
                            )}

                            {item.status === "failed" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Failed
                                </span>
                              </span>
                            )}

                            {item.status === "pending" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  In progress
                                </span>
                              </span>
                            )}
                          </td>
                          <td className="p-4 whitespace-nowrap text-left text-sm font-semibold text-gray-900">
                            {item.tx_type}
                          </td>
                          <td className="p-4 whitespace-nowrap text-left text-sm font-semibold text-gray-900">
                            {item.description}
                          </td>
                          <td className="p-4 whitespace-nowrap text-left text-sm font-semibold text-gray-900">
                            {/* {item.admin_updatedBy} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={openTab === 2 ? "block" : "hidden"}>
        <div className="flex flex-col my-8">
          <div className="overflow-x-auto rounded-lg h-[32rem] scrollbar-hide">
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden sm:rounded-lg">
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoading />
                  </div>
                ) : error ? (
                  <div className="pt-5">
                    <Loading />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          ACCOUNT NAME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          DATE & TIME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          AMOUNT
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          STATUS
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          ADMIN
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white">
                      {cryptoTransaction.map((item, i) => (
                        <tr
                          key={item.id}
                          className={i % 2 === 0 ? "" : "bg-gray-50"}
                        >
                          <td
                            onClick={() => getCryptoHandler(item.id)}
                            className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                fill="#141B34"
                              />
                              <path
                                d="M7.33337 6.41667H14.6667"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.33337 10.0833H11"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <span className="font-semibold">
                              {item.user === null ? "" : item.user.full_name}
                            </span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                            <FormattedDate date={item?.created_at} />
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            USD {item.USD_rate.toLocaleString()}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.status === "approve" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Approved
                                </span>
                              </span>
                            )}

                            {item.status === "declined" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Declined
                                </span>
                              </span>
                            )}

                            {item.status === "pending" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  In progress
                                </span>
                              </span>
                            )}
                            {item.status === "completed" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Completed
                                </span>
                              </span>
                            )}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.admin_updatedBy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={openTab === 3 ? "block" : "hidden"}>
        <div className="flex flex-col my-8">
          <div className="overflow-x-auto rounded-lg h-[32rem] scrollbar-hide">
            <div className="align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden sm:rounded-lg">
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoading />
                  </div>
                ) : error ? (
                  <div className="pt-5">
                    <Loading />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          ACCOUNT NAME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          DATE & TIME
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          AMOUNT
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          STATUS
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          ADMIN
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white">
                      {giftcardTransaction.map((item, i) => (
                        <tr
                          key={item.id}
                          className={i % 2 === 0 ? "" : "bg-gray-50"}
                        >
                          <td
                            onClick={() => getGiftCardHandler(item.id)}
                            className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                          >
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                fill="#141B34"
                              />
                              <path
                                d="M7.33337 6.41667H14.6667"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.33337 10.0833H11"
                                stroke="#141B34"
                                stroke-width="1.5"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <span className="font-semibold">
                              {item.user === null ? "" : item.user.full_name}
                            </span>
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                            <FormattedDate date={item?.created_at} />
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.card_range}{" "}
                            {item.amount_of_the_card.toLocaleString()}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.status === "approve" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Approved
                                </span>
                              </span>
                            )}
                            {item.status === "approved" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Approved
                                </span>
                              </span>
                            )}
                            {item.status === "declined" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Declined
                                </span>
                              </span>
                            )}
                            {item.status === "pending" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  In progress
                                </span>
                              </span>
                            )}{" "}
                            {item.status === "completed" && (
                              <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                <span
                                  aria-hidden
                                  className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                ></span>
                                <span className="relative text-xs font-normal">
                                  Completed
                                </span>
                              </span>
                            )}
                          </td>
                          <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                            {item.admin_updatedBy}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-5xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold">
                  Recent Pending Transaction
                </h3>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModal(false);
                  }}
                  type="button"
                  className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-toggle="default-modal"
                >
                  <svg
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <div className="flex flex-col my-5">
                <div className="overflow-x-auto rounded-lg  h-[32rem] scrollbar-hide">
                  <div className="align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden sm:rounded-lg">
                      {isLoading ? (
                        <div className="pb-5">
                          <Loading />
                        </div>
                      ) : error ? (
                        <div className="pt-5">
                          <Loading />
                        </div>
                      ) : (
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                              >
                                TRANSACTION
                              </th>
                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                              >
                                DATE & TIME
                              </th>
                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                              >
                                AMOUNT
                              </th>
                              <th
                                scope="col"
                                className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                              >
                                STATUS
                              </th>
                            </tr>
                          </thead>

                          <tbody className="bg-white">
                            {billsTransaction

                              .filter((item) => item.status === "pending")
                              .map((item, i) => (
                                <tr
                                  key={item.id}
                                  className={i % 2 === 0 ? "" : "bg-gray-50"}
                                >
                                  <td
                                    onClick={() => getHandler(item.id)}
                                    className="p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2 cursor-pointer"
                                  >
                                    <svg
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                        fill="#141B34"
                                      />
                                      <path
                                        d="M7.33337 6.41667H14.6667"
                                        stroke="#141B34"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.33337 10.0833H11"
                                        stroke="#141B34"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    Payment from{" "}
                                    <span className="font-semibold">
                                      {item.user.full_name}
                                    </span>
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-sm text-left font-normal text-gray-500">
                                    <FormattedDate date={item?.created_at} />
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-sm text-left font-semibold text-gray-900">
                                    ₦ {item.amount.toLocaleString()}
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                                    <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                      <span
                                        aria-hidden
                                        className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                      ></span>
                                      <span className="relative text-xs font-normal">
                                        In progress
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                          <tbody className="bg-white">
                            {cryptoTransaction
                              .filter((item) => item.status === "pending")
                              .map((item, i) => (
                                <tr
                                  key={item.id}
                                  className={i % 2 === 0 ? "" : "bg-gray-50"}
                                >
                                  <td
                                    onClick={() => getCryptoHandler(item.id)}
                                    className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                                  >
                                    <svg
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                        fill="#141B34"
                                      />
                                      <path
                                        d="M7.33337 6.41667H14.6667"
                                        stroke="#141B34"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.33337 10.0833H11"
                                        stroke="#141B34"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                    </svg>

                                    <span className="font-semibold">
                                      {item.user === null
                                        ? ""
                                        : item.user.full_name}
                                    </span>
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-left text-sm font-normal text-gray-500">
                                    <FormattedDate date={item?.created_at} />
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-left text-sm font-semibold text-gray-900">
                                    USD {item.USD_rate.toLocaleString()}
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-left text-sm font-semibold text-gray-900">
                                    <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                      <span
                                        aria-hidden
                                        className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                      ></span>
                                      <span className="relative text-xs font-normal">
                                        In progress
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                          <tbody className="bg-white">
                            {giftcardTransaction
                              .filter((item) => item.status === "pending")
                              .map((item, i) => (
                                <tr
                                  key={item.id}
                                  className={i % 2 === 0 ? "" : "bg-gray-50"}
                                >
                                  <td
                                    onClick={() => getGiftCardHandler(item.id)}
                                    className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 flex gap-x-2"
                                  >
                                    <svg
                                      width="22"
                                      height="22"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M3.20837 1.83333V1.14583C2.82868 1.14583 2.52087 1.45364 2.52087 1.83333H3.20837ZM18.7917 1.83333H19.4792C19.4792 1.45364 19.1714 1.14583 18.7917 1.14583V1.83333ZM3.20837 20.1667H2.52087C2.52087 20.349 2.59331 20.5239 2.72224 20.6528C2.85117 20.7817 3.02604 20.8542 3.20837 20.8542V20.1667ZM12.375 20.1667V20.8542H12.6598L12.8611 20.6528L12.375 20.1667ZM18.7917 13.75L19.2778 14.2361L19.4792 14.0348V13.75H18.7917ZM12.375 13.75V13.0625H11.6875V13.75H12.375ZM3.20837 2.52083H18.7917V1.14583H3.20837V2.52083ZM3.89587 20.1667V1.83333H2.52087V20.1667H3.89587ZM3.20837 20.8542H12.375V19.4792H3.20837V20.8542ZM19.4792 13.75V1.83333H18.1042V13.75H19.4792ZM12.8611 20.6528L19.2778 14.2361L18.3056 13.2639L11.8889 19.6806L12.8611 20.6528ZM13.0625 20.1667V13.75H11.6875V20.1667H13.0625ZM12.375 14.4375H18.7917V13.0625H12.375V14.4375Z"
                                        fill="#141B34"
                                      />
                                      <path
                                        d="M7.33337 6.41667H14.6667"
                                        stroke="#141B34"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M7.33337 10.0833H11"
                                        stroke="#141B34"
                                        stroke-width="1.5"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    Payment from{" "}
                                    <span className="font-semibold">
                                      {item.user === null
                                        ? ""
                                        : item.user.full_name}
                                    </span>
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-left text-sm font-normal text-gray-500">
                                    <FormattedDate date={item?.created_at} />
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-left text-sm font-semibold text-gray-900">
                                    {item.card_range}{" "}
                                    {item.amount_of_the_card.toLocaleString()}
                                  </td>
                                  <td className="p-4 whitespace-nowrap text-sm text-left font-semibold text-gray-900">
                                    <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                                      <span
                                        aria-hidden
                                        className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                                      ></span>
                                      <span className="relative text-xs font-normal">
                                        In progress
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {transactionModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setTransactionModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>

              <div className="py-2">
                {singleTrans.status === "completed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Completed
                    </span>
                  </span>
                )}

                {singleTrans.status === "failed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">Failed</span>
                  </span>
                )}

                {singleTrans.status === "pending" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      In progress
                    </span>
                  </span>
                )}
              </div>
              {/* <h3 className="text-[#000] text-lg font-semibold py-3">
																		Confirm Account Ban
																	</h3> */}

              <div className="w-full py-1">
                <h2 className="text-[#000] text-left  text-sm font-semibold">
                  Transaction Reference
                </h2>
                <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                  {singleTrans.reference}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#000]  text-left   text-sm font-semibold">
                  Transaction Date & Time
                </h2>
                <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                  <FormattedDate date={singleTrans.created_at} />
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#000]  text-left   text-sm font-semibold">
                  Transaction type
                </h2>
                <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                  {singleTrans.tx_type}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#000]  text-left   text-sm font-semibold">
                  Amount
                </h2>
                <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                  {singleTrans.amount_currency} {singleTrans.amount}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#000]  text-left   text-sm font-semibold">
                  Description
                </h2>
                <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                  {singleTrans.description}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#000]  text-left   text-sm font-semibold">
                  Channel
                </h2>
                <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                  {singleTrans.channel}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Admin
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] p-4  text-sm font-normal  rounded-lg mt-1 text-left">
                  {singleTrans.admin_updatedBy}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {cryptoModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="py-2">
                {singleCrypto.status === "approve" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Approved
                    </span>
                  </span>
                )}

                {singleCrypto.status === "cancelled" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Cancelled
                    </span>
                  </span>
                )}

                {singleCrypto.status === "pending" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      In progress
                    </span>
                  </span>
                )}
              </div>
              <h3 className="text-[#000] text-lg font-semibold py-3">
                Crypto transaction Info
              </h3>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C] text-left  text-sm font-semibold ">
                  Transaction Reference
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.reference}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Transaction Date & Time
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  <FormattedDate date={singleCrypto?.created_at} />
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.asset_type}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Amount
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  USD {singleCrypto.USD_rate}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-center text-sm font-semibold">
                  <svg
                    className="mx-auto my-1"
                    width="20"
                    height="13"
                    viewBox="0 0 20 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3.13184C4 0.203219 7 1.06033 10 3.20313C13 5.34591 16 6.20304 19 3.27442"
                      stroke="#141B34"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1 10.1631C4 7.23446 7 8.09159 10 10.2344C13 12.3772 16 13.2343 19 10.3057"
                      stroke="#141B34"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  NGN {singleCrypto.NGN_rate}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.user.full_name}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Phone Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.user.phone_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Account Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.user.account_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Bank Name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleCrypto.user.bank_name}
                </div>
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset Image
                </h2>

                <img
                  src={singleCrypto.image}
                  alt="card"
                  className="h-auto w-full py-1"
                />
              </div>
              {singleCrypto.status === "pending" ? (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      const id = singleCrypto.id;
                      approveHandler(id);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                  >
                    {loading ? <Spinner /> : "Approve"}
                  </button>
                  <button
                    onClick={() => {
                      setCryptoModal(false);
                      setDeclineCryptoModal(true);
                      setDeclineId(singleCrypto.id);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => {
                      setCryptoModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              ) : (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      setCryptoModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {giftCardModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="py-2">
                {singleGiftCard.status === "completed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Completed
                    </span>
                  </span>
                )}
                {singleGiftCard.status === "approved" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Approved
                    </span>
                  </span>
                )}

                {singleGiftCard.status === "approve" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Approved
                    </span>
                  </span>
                )}

                {singleGiftCard.status === "cancelled" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Cancelled
                    </span>
                  </span>
                )}

                {singleGiftCard.status === "pending" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      In progress
                    </span>
                  </span>
                )}
              </div>
              <h3 className="text-[#000] text-lg font-semibold py-3">
                Gift Card transaction Info
              </h3>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C] text-left  text-sm font-semibold ">
                  Transaction Reference
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.reference}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Transaction Date & Time
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  <FormattedDate date={singleGiftCard?.created_at} />
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.card_company}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset Country
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.country}
                </div>
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Asset Image
                </h2>

                {singleGiftCard.image.slice(0, 2).map((item, i) => (
                  <img
                    key={i}
                    src={item.url}
                    alt="card"
                    className="h-auto w-full py-1"
                  />
                ))}
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Card Value
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.card_range}{" "}
                  {singleGiftCard.amount_of_the_card.toLocaleString()}
                </div>
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Transaction Value
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.card_range}{" "}
                  {singleGiftCard.transaction_value.toLocaleString()}
                </div>
              </div>

              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.full_name}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Phone Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.phone_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Account Number
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.account_number}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Sender’s Bank Name
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {singleGiftCard.user.bank_name}
                </div>
              </div>

              {singleGiftCard.status === "pending" ? (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                      setApproveId(singleGiftCard.id);
                      setApproveGiftCardModal(true);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                  >
                    {loading ? <Spinner /> : "Approve"}
                  </button>
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                      setDeclineGiftCardModal(true);
                      setDeclineId(singleGiftCard.id);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              ) : (
                <div className="flex gap-x-3 items-start justify-between mt-2">
                  <button
                    onClick={() => {
                      setGiftCardModal(false);
                    }}
                    className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {declineCryptoModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeclineCryptoModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <DeclineCryptoForm
                id={declineId}
                setDeclineCryptoModal={setDeclineCryptoModal}
              />
            </div>
          </div>
        </div>
      )}
      {declineGiftCardModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeclineGiftCardModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <DeclineGiftForm
                id={declineId}
                setDeclineGiftCardModal={setDeclineGiftCardModal}
              />
            </div>
          </div>
        </div>
      )}
      {fundModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setFundModal(false);
                  }}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm  ml-auto inline-flex items-center "
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Fund User
              </h3>

              <Fund setFundModal={setFundModal} />
            </div>
          </div>
        </div>
      )}
      {approveGiftCardModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setApproveGiftCardModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <ApproveGiftForm
                id={approveId}
                setApproveGiftCardModal={setApproveGiftCardModal}
                refetch={refetch}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Transactions;
