import { apiOne, apiTwo } from "./apiSlice";

export const userApiSlice = apiOne.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `admin/loginAdmin`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation } = userApiSlice;

export const userAuthApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    createAdmin: builder.mutation({
      query: (data) => ({
        url: `admin/registerAdmin`,
        method: "POST",
        body: data,
      }),
    }),

    getAllUsers: builder.query({
      query: () => ({
        url: `admin/users`,
        keepUnusedDataFor: 5,
      }),
    }),
    getAllAdmins: builder.query({
      query: () => ({
        url: `admin/allAdmin`,
        keepUnusedDataFor: 5,
      }),
    }),
    getUser: builder.query({
      query: (id) => ({
        url: `admin/user/${id}`,
        keepUnusedDataFor: 5,
      }),
    }),

    banUser: builder.mutation({
      query: (data) => ({
        url: `admin/banUser`,
        method: "POST",
        body: data,
      }),
    }),

    deleteUser: builder.mutation({
      query: (data) => ({
        url: `admin/delete/${data}`,
        method: "DELETE",
      }),
    }),
    deleteAdmin: builder.mutation({
      query: (data) => ({
        url: `admin/deleteAdmin/${data}`,
        method: "DELETE",
      }),
    }),
    getRquestFunding: builder.query({
      query: () => ({
        url: `admin/fetchUsersRquestFunding`,
        keepUnusedDataFor: 5,
      }),
    }),
    getResolution: builder.query({
      query: () => ({
        url: `admin/getCustomerResolution`,
        keepUnusedDataFor: 5,
      }),
    }),
  }),
});

export const {
  useCreateAdminMutation,
  useGetAllUsersQuery,
  useBanUserMutation,
  useGetUserQuery,
  useDeleteUserMutation,
  useGetAllAdminsQuery,
  useDeleteAdminMutation,
  useGetRquestFundingQuery,
  useGetResolutionQuery,
} = userAuthApiSlice;
