import React, { useState } from "react";
import { BASE_URL } from "../utils";
import axios from "axios";
import FormattedDate from "./FormattedDate";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function Search() {
  const [modal, setModal] = useState(false);
  const [reference, setReference] = useState([]);
  const [referenceNumber, setReferenceNumber] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const fetchTrans = async () => {
    try {
      // Make a GET request to the Flutterwave API endpoint for bank list
      const response = await axios.post(
        `${BASE_URL}admin/searchReferenceQuery`,
        { referenceNumber },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      // Set the retrieved bank data to the state
      setReference(response.data.reference);
      setModal(true);
    } catch (error) {
      toast.error("Error fetching reference:", error);
    }
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetchTrans();
        }}
        className="relative mx-3"
      >
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            className="w-4 h-4 text-gray-400"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </span>

        <input
          type="text"
          required
          className="w-full py-2 pl-8 pr-4 text-gray-700 bg-[#F9F9F9] border rounded-md placeholder:text-xs  focus:border-blue-400  focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring"
          placeholder="Search"
          value={referenceNumber}
          onChange={(e) => {
            setReferenceNumber(e.target.value);
          }}
        />
      </form>
      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-lg sm:p-6 md:p-8 sm:align-middle ">
              <div className="py-2">
                {reference.status === "approve" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Approved
                    </span>
                  </span>
                )}
                {reference.status === "completed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Completed
                    </span>
                  </span>
                )}
                {reference.status === "cancelled" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      Cancelled
                    </span>
                  </span>
                )}
                {reference.status === "failed" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#CA0D0D] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#FBD5D5] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">Failed</span>
                  </span>
                )}

                {reference.status === "pending" && (
                  <span className="relative inline-block px-3 py-1 font-semibold text-[#1E429F] leading-tight">
                    <span
                      aria-hidden
                      className="absolute inset-0 bg-[#E1EFFE] opacity-50 rounded-full"
                    ></span>
                    <span className="relative text-sm font-normal">
                      In progress
                    </span>
                  </span>
                )}
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Customer's ID
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {reference.user_id}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C] text-left  text-sm font-semibold ">
                  Transaction ID
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {reference.id}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C] text-left  text-sm font-semibold ">
                  Transaction Reference
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  {reference.reference}
                </div>
              </div>
              <div className="w-full py-1">
                <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                  Transaction Date & Time
                </h2>
                <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                  <FormattedDate date={reference?.created_at} />
                </div>
              </div>
              {reference.tx_type && (
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-sm font-thin ">
                    Transaction type
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {reference.tx_type}
                  </div>
                </div>
              )}
              {reference.amount && (
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-sm font-thin ">
                    Amount
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {reference.amount}
                  </div>
                </div>
              )}
              {reference.description && (
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-sm font-thin ">
                    Description
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {reference.description}
                  </div>
                </div>
              )}
              {reference.channel && (
                <div className="w-full py-1">
                  <h2 className="text-[#000]  text-left   text-sm font-thin ">
                    Channel
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#000] p-4  text-sm font-normal lightnded-lg mt-1 text-left">
                    {reference.channel}
                  </div>
                </div>
              )}
              {reference.network_type && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                    Asset Network
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                    {reference.network_type}
                  </div>
                </div>
              )}
              {reference.asset_type && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                    Asset
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                    {reference.asset_type}
                  </div>
                </div>
              )}
              {reference.USD_rate && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                    Amount
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                    USD {reference.USD_rate}
                  </div>
                </div>
              )}
              {reference.NGN_rate && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-center text-sm font-thin ">
                    <svg
                      className="mx-auto my-1"
                      width="20"
                      height="13"
                      viewBox="0 0 20 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 3.13184C4 0.203219 7 1.06033 10 3.20313C13 5.34591 16 6.20304 19 3.27442"
                        stroke="#141B34"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 10.1631C4 7.23446 7 8.09159 10 10.2344C13 12.3772 16 13.2343 19 10.3057"
                        stroke="#141B34"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                    NGN {reference.NGN_rate}
                  </div>
                </div>
              )}
              {reference.image && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left text-sm font-semibold ">
                    Asset Image
                  </h2>

                  <img
                    src={reference.image}
                    alt="card"
                    className="h-auto w-full py-1"
                  />
                </div>
              )}
              {reference.card_company && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                    Asset
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                    {reference.card_company}
                  </div>
                </div>
              )}
              {/* {reference.image && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                    Asset Image
                  </h2>

                  {reference.image.slice(0, 2).map((item, i) => (
                    <img
                      key={i}
                      src={item.url}
                      alt="card"
                      className="h-auto w-full py-1"
                    />
                  ))}
                </div>
              )} */}
              {reference.amount_of_the_card && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                    Card Value
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                    {reference.amount_of_the_card.toLocaleString()}
                  </div>
                </div>
              )}
              {reference.transaction_value && (
                <div className="w-full py-1">
                  <h2 className="text-[#0C0C0C]  text-left   text-sm font-semibold ">
                    Transaction Value
                  </h2>
                  <div className="bg-[#F9FAFB] text-[#979797] py-3 px-4 text-xs font-normal  rounded-lg mt-1 text-left">
                    {reference.transaction_value}
                  </div>
                </div>
              )}

              <div className="flex gap-x-3 items-start justify-between mt-2">
                <button
                  onClick={() => {
                    setModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-gray-500  px-3 py-4 rounded-lg "
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Search;
