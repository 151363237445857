import React from 'react';
import { Link } from 'react-router-dom';

function Feedback() {
	function truncate(str, n) {
		return str?.length > n ? str.substr(0, n - 1) + '...' : str;
	}
	return (
		<div className="px-5">
			<div className="flex justify-between gap-x-2  my-10 ">
				<div className="">
					<h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
						Home{' '}
						<span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>{' '}
						Customer Support Centre
						<span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								class="w-5 h-5"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
									clip-rule="evenodd"
								/>
							</svg>
						</span>{' '}
						User Feedback
					</h2>

					<Link
						to="/dashboard/support"
						className="text-[#4E4E4E] text-sm font-light text-left flex items-center gap-x-2 mt-3"
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
								stroke="#0C0C0C"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
								stroke="#0C0C0C"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						Back
					</Link>
				</div>
			</div>

			<div class="grid grid-cols-1 lg:grid-cols-12 gap-4 my-10">
				<div className="sm:col-span-9">
					<h2 className="text-[#0C0C0C] font-extrabold text-xl mb-5 text-left  block ">
						User Feedback
					</h2>

					<div className="grid grid-cols-6 lg:grid-cols-12 gap-3">
						<div className="sm:col-span-4 border border-[#E1EFFE] rounded-lg p-4">
							<div className="flex  justify-between ">
								<svg
									width="54"
									height="54"
									viewBox="0 0 54 54"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 0C12.096 0 0 12.096 0 27C0 41.904 12.096 54 27 54C41.904 54 54 41.904 54 27C54 12.096 41.904 0 27 0ZM27 10.8C32.211 10.8 36.45 15.039 36.45 20.25C36.45 25.461 32.211 29.7 27 29.7C21.789 29.7 17.55 25.461 17.55 20.25C17.55 15.039 21.789 10.8 27 10.8ZM27 48.6C21.519 48.6 15.039 46.386 10.422 40.824C15.1513 37.1134 20.9888 35.0968 27 35.0968C33.0112 35.0968 38.8487 37.1134 43.578 40.824C38.961 46.386 32.481 48.6 27 48.6Z"
										fill="#E7E7E7"
									/>
								</svg>
								<div>
									<h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-right ">
										Daniel Akpabio S.
									</h2>
									<div className="flex justify-end ">
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
									</div>
								</div>
							</div>
							<div className="flex items-end justify-between mt-5">
								<h2 className="text-[#0C0C0C] font-normal text-sm py-1 w-[10rem] ">
									{truncate(
										'Lorem ipsum dolor sit amet consectetur. Tortor non vel risus maecenas ullamcorper vel. Egestas feugiat sagittis lobortis velit ut eu cursus amet. Pulvinar cursus in sodales massa diam fusce et. Sapien non sit massa at dui volutpat.',
										88
									)}
								</h2>

								<Link
									to=""
									className="bg-[#fff] border border-[#E1EFFE] py-1 px-3 rounded-md text-xs"
								>
									View
								</Link>
							</div>
						</div>
						<div className="sm:col-span-4 border border-[#E1EFFE] rounded-lg p-4">
							<div className="flex  justify-between ">
								<svg
									width="54"
									height="54"
									viewBox="0 0 54 54"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 0C12.096 0 0 12.096 0 27C0 41.904 12.096 54 27 54C41.904 54 54 41.904 54 27C54 12.096 41.904 0 27 0ZM27 10.8C32.211 10.8 36.45 15.039 36.45 20.25C36.45 25.461 32.211 29.7 27 29.7C21.789 29.7 17.55 25.461 17.55 20.25C17.55 15.039 21.789 10.8 27 10.8ZM27 48.6C21.519 48.6 15.039 46.386 10.422 40.824C15.1513 37.1134 20.9888 35.0968 27 35.0968C33.0112 35.0968 38.8487 37.1134 43.578 40.824C38.961 46.386 32.481 48.6 27 48.6Z"
										fill="#E7E7E7"
									/>
								</svg>
								<div>
									<h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-right ">
										Daniel Akpabio S.
									</h2>
									<div className="flex justify-end ">
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
									</div>
								</div>
							</div>
							<div className="flex items-end justify-between mt-5">
								<h2 className="text-[#0C0C0C] font-normal text-sm py-1 w-[10rem] ">
									{truncate(
										'Lorem ipsum dolor sit amet consectetur. Tortor non vel risus maecenas ullamcorper vel. Egestas feugiat sagittis lobortis velit ut eu cursus amet. Pulvinar cursus in sodales massa diam fusce et. Sapien non sit massa at dui volutpat.',
										88
									)}
								</h2>

								<Link
									to=""
									className="bg-[#fff] border border-[#E1EFFE] py-1 px-3 rounded-md text-xs"
								>
									View
								</Link>
							</div>
						</div>
						<div className="sm:col-span-4 border border-[#E1EFFE] rounded-lg p-4">
							<div className="flex  justify-between ">
								<svg
									width="54"
									height="54"
									viewBox="0 0 54 54"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 0C12.096 0 0 12.096 0 27C0 41.904 12.096 54 27 54C41.904 54 54 41.904 54 27C54 12.096 41.904 0 27 0ZM27 10.8C32.211 10.8 36.45 15.039 36.45 20.25C36.45 25.461 32.211 29.7 27 29.7C21.789 29.7 17.55 25.461 17.55 20.25C17.55 15.039 21.789 10.8 27 10.8ZM27 48.6C21.519 48.6 15.039 46.386 10.422 40.824C15.1513 37.1134 20.9888 35.0968 27 35.0968C33.0112 35.0968 38.8487 37.1134 43.578 40.824C38.961 46.386 32.481 48.6 27 48.6Z"
										fill="#E7E7E7"
									/>
								</svg>
								<div>
									<h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-right ">
										Daniel Akpabio S.
									</h2>
									<div className="flex justify-end ">
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
									</div>
								</div>
							</div>
							<div className="flex items-end justify-between mt-5">
								<h2 className="text-[#0C0C0C] font-normal text-sm py-1 w-[10rem] ">
									{truncate(
										'Lorem ipsum dolor sit amet consectetur. Tortor non vel risus maecenas ullamcorper vel. Egestas feugiat sagittis lobortis velit ut eu cursus amet. Pulvinar cursus in sodales massa diam fusce et. Sapien non sit massa at dui volutpat.',
										88
									)}
								</h2>

								<Link
									to=""
									className="bg-[#fff] border border-[#E1EFFE] py-1 px-3 rounded-md text-xs"
								>
									View
								</Link>
							</div>
						</div>
						<div className="sm:col-span-4 border border-[#E1EFFE] rounded-lg p-4">
							<div className="flex  justify-between ">
								<svg
									width="54"
									height="54"
									viewBox="0 0 54 54"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 0C12.096 0 0 12.096 0 27C0 41.904 12.096 54 27 54C41.904 54 54 41.904 54 27C54 12.096 41.904 0 27 0ZM27 10.8C32.211 10.8 36.45 15.039 36.45 20.25C36.45 25.461 32.211 29.7 27 29.7C21.789 29.7 17.55 25.461 17.55 20.25C17.55 15.039 21.789 10.8 27 10.8ZM27 48.6C21.519 48.6 15.039 46.386 10.422 40.824C15.1513 37.1134 20.9888 35.0968 27 35.0968C33.0112 35.0968 38.8487 37.1134 43.578 40.824C38.961 46.386 32.481 48.6 27 48.6Z"
										fill="#E7E7E7"
									/>
								</svg>
								<div>
									<h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-right ">
										Daniel Akpabio S.
									</h2>
									<div className="flex justify-end ">
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
									</div>
								</div>
							</div>
							<div className="flex items-end justify-between mt-5">
								<h2 className="text-[#0C0C0C] font-normal text-sm py-1 w-[10rem] ">
									{truncate(
										'Lorem ipsum dolor sit amet consectetur. Tortor non vel risus maecenas ullamcorper vel. Egestas feugiat sagittis lobortis velit ut eu cursus amet. Pulvinar cursus in sodales massa diam fusce et. Sapien non sit massa at dui volutpat.',
										88
									)}
								</h2>

								<Link
									to=""
									className="bg-[#fff] border border-[#E1EFFE] py-1 px-3 rounded-md text-xs"
								>
									View
								</Link>
							</div>
						</div>
						<div className="sm:col-span-4 border border-[#E1EFFE] rounded-lg p-4">
							<div className="flex  justify-between ">
								<svg
									width="54"
									height="54"
									viewBox="0 0 54 54"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 0C12.096 0 0 12.096 0 27C0 41.904 12.096 54 27 54C41.904 54 54 41.904 54 27C54 12.096 41.904 0 27 0ZM27 10.8C32.211 10.8 36.45 15.039 36.45 20.25C36.45 25.461 32.211 29.7 27 29.7C21.789 29.7 17.55 25.461 17.55 20.25C17.55 15.039 21.789 10.8 27 10.8ZM27 48.6C21.519 48.6 15.039 46.386 10.422 40.824C15.1513 37.1134 20.9888 35.0968 27 35.0968C33.0112 35.0968 38.8487 37.1134 43.578 40.824C38.961 46.386 32.481 48.6 27 48.6Z"
										fill="#E7E7E7"
									/>
								</svg>
								<div>
									<h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-right ">
										Daniel Akpabio S.
									</h2>
									<div className="flex justify-end ">
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
									</div>
								</div>
							</div>
							<div className="flex items-end justify-between mt-5">
								<h2 className="text-[#0C0C0C] font-normal text-sm py-1 w-[10rem] ">
									{truncate(
										'Lorem ipsum dolor sit amet consectetur. Tortor non vel risus maecenas ullamcorper vel. Egestas feugiat sagittis lobortis velit ut eu cursus amet. Pulvinar cursus in sodales massa diam fusce et. Sapien non sit massa at dui volutpat.',
										88
									)}
								</h2>

								<Link
									to=""
									className="bg-[#fff] border border-[#E1EFFE] py-1 px-3 rounded-md text-xs"
								>
									View
								</Link>
							</div>
						</div>
						<div className="sm:col-span-4 border border-[#E1EFFE] rounded-lg p-4">
							<div className="flex  justify-between ">
								<svg
									width="54"
									height="54"
									viewBox="0 0 54 54"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M27 0C12.096 0 0 12.096 0 27C0 41.904 12.096 54 27 54C41.904 54 54 41.904 54 27C54 12.096 41.904 0 27 0ZM27 10.8C32.211 10.8 36.45 15.039 36.45 20.25C36.45 25.461 32.211 29.7 27 29.7C21.789 29.7 17.55 25.461 17.55 20.25C17.55 15.039 21.789 10.8 27 10.8ZM27 48.6C21.519 48.6 15.039 46.386 10.422 40.824C15.1513 37.1134 20.9888 35.0968 27 35.0968C33.0112 35.0968 38.8487 37.1134 43.578 40.824C38.961 46.386 32.481 48.6 27 48.6Z"
										fill="#E7E7E7"
									/>
								</svg>
								<div>
									<h2 className="text-[#0C0C0C] font-semibold text-base py-1 text-right ">
										Daniel Akpabio S.
									</h2>
									<div className="flex justify-end ">
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
										<svg
											className="w-5 h-5"
											width="13"
											height="12"
											viewBox="0 0 13 12"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M6.69533 8.81078L4.73098 10.0007C4.64421 10.0562 4.55341 10.0801 4.45858 10.0724C4.36375 10.0648 4.28072 10.0334 4.20948 9.97824C4.13824 9.92311 4.08277 9.85419 4.04306 9.77149C4.00336 9.68847 3.99523 9.59558 4.01868 9.49282L4.53465 7.25173L2.7889 5.75092C2.70972 5.68001 2.66022 5.59907 2.64041 5.50811C2.62027 5.41746 2.6259 5.32864 2.65729 5.24166C2.68868 5.15467 2.73591 5.08345 2.79898 5.02799C2.86205 4.97254 2.94887 4.93677 3.05944 4.9207L5.35802 4.71362L6.24168 2.602C6.28095 2.50709 6.34209 2.43584 6.42508 2.38823C6.50777 2.34063 6.59256 2.31672 6.67947 2.31651C6.76637 2.31629 6.85144 2.33979 6.93467 2.38699C7.01759 2.43419 7.07891 2.50515 7.11864 2.59986L8.01261 4.70714L10.3122 4.90299C10.4228 4.91852 10.5098 4.95386 10.5731 5.00901C10.6365 5.06416 10.6841 5.13515 10.7159 5.22198C10.7477 5.30881 10.7539 5.39759 10.7345 5.48834C10.7149 5.5794 10.6656 5.66059 10.5868 5.73188L8.84836 7.2412L9.37527 9.47974C9.39922 9.58238 9.39155 9.67531 9.35225 9.75853C9.31295 9.84142 9.25781 9.91061 9.18684 9.96608C9.11587 10.0216 9.03299 10.0534 8.93821 10.0615C8.84342 10.0696 8.7525 10.0462 8.66546 9.99106L6.69533 8.81078Z"
												fill="#FFD700"
											/>
										</svg>
									</div>
								</div>
							</div>
							<div className="flex items-end justify-between mt-5">
								<h2 className="text-[#0C0C0C] font-normal text-sm py-1 w-[10rem] ">
									{truncate(
										'Lorem ipsum dolor sit amet consectetur. Tortor non vel risus maecenas ullamcorper vel. Egestas feugiat sagittis lobortis velit ut eu cursus amet. Pulvinar cursus in sodales massa diam fusce et. Sapien non sit massa at dui volutpat.',
										88
									)}
								</h2>

								<Link
									to=""
									className="bg-[#fff] border border-[#E1EFFE] py-1 px-3 rounded-md text-xs"
								>
									View
								</Link>
							</div>
						</div>
					
					</div>
				</div>
				<div className="sm:col-span-3">
					<div className=" my-3 p-3 bg-gradient-to-b from-[#284C84]  to-[#062F70] rounded-md  ">
						<h2 className="text-[#fff] font-semibold text-base px-4 py-2 text-left  block ">
							Quick Access
						</h2>
						<button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14.3282 0.0287922L0.407274 4.66911C0.298158 4.70547 0.201879 4.7726 0.130029 4.86241C0.0581799 4.95222 0.0138236 5.06089 0.00230107 5.17532C-0.00743642 5.28588 0.0138567 5.39696 0.0637867 5.49607C0.113717 5.59518 0.190304 5.67841 0.284939 5.73638L6.0769 9.03101L9.34199 14.8272C9.38851 14.9081 9.45338 14.977 9.53136 15.0283C9.60935 15.0796 9.69828 15.1118 9.79101 15.1225C9.88374 15.1332 9.97768 15.1219 10.0653 15.0897C10.1529 15.0575 10.2317 15.0051 10.2954 14.9369C10.361 14.8704 10.4101 14.7895 10.4388 14.7006L15.0791 0.77968C15.1128 0.67518 15.117 0.563408 15.0913 0.456671C15.0655 0.349934 15.0108 0.252375 14.9332 0.174735C14.8555 0.0970955 14.758 0.0423883 14.6512 0.0166382C14.5445 -0.00911194 14.4327 -0.00490642 14.3282 0.0287922ZM9.73009 13.0639L7.2412 8.7104L11.1391 4.81254L10.2954 3.96884L6.3975 7.86671L2.04404 5.37781L13.5689 1.539L9.73009 13.0639Z"
									fill="white"
								/>
							</svg>{' '}
							Fund an Account
						</button>
						<button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g clip-path="url(#clip0_761_4964)">
									<path
										d="M5.33317 7.33333C5.15636 7.33333 4.98679 7.40357 4.86177 7.52859C4.73674 7.65361 4.6665 7.82318 4.6665 7.99999C4.6665 8.17681 4.73674 8.34637 4.86177 8.4714C4.98679 8.59642 5.15636 8.66666 5.33317 8.66666H10.6665C10.8433 8.66666 11.0129 8.59642 11.1379 8.4714C11.2629 8.34637 11.3332 8.17681 11.3332 7.99999C11.3332 7.82318 11.2629 7.65361 11.1379 7.52859C11.0129 7.40357 10.8433 7.33333 10.6665 7.33333H5.33317Z"
										fill="white"
									/>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M15.3332 8C15.3332 12.05 12.0498 15.3333 7.99984 15.3333C3.94984 15.3333 0.666504 12.05 0.666504 8C0.666504 3.95 3.94984 0.666664 7.99984 0.666664C12.0498 0.666664 15.3332 3.95 15.3332 8ZM13.9998 8C13.9998 8.78793 13.8446 9.56814 13.5431 10.2961C13.2416 11.0241 12.7996 11.6855 12.2425 12.2426C11.6853 12.7998 11.0239 13.2417 10.2959 13.5433C9.56798 13.8448 8.78777 14 7.99984 14C7.21191 14 6.43169 13.8448 5.70374 13.5433C4.97578 13.2417 4.31435 12.7998 3.7572 12.2426C3.20004 11.6855 2.75809 11.0241 2.45656 10.2961C2.15503 9.56814 1.99984 8.78793 1.99984 8C1.99984 6.4087 2.63198 4.88258 3.7572 3.75736C4.88241 2.63214 6.40854 2 7.99984 2C9.59114 2 11.1173 2.63214 12.2425 3.75736C13.3677 4.88258 13.9998 6.4087 13.9998 8Z"
										fill="white"
									/>
								</g>
								<defs>
									<clipPath id="clip0_761_4964">
										<rect width="16" height="16" fill="white" />
									</clipPath>
								</defs>
							</svg>
							Debit an Account
						</button>
						<button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
							<svg
								width="15"
								height="16"
								viewBox="0 0 15 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7.49994 0.66214C8.87734 0.66214 10.2238 1.07059 11.3691 1.83583C12.5144 2.60108 13.407 3.68875 13.9341 4.96131C14.4612 6.23387 14.5991 7.63415 14.3304 8.98509C14.0617 10.336 13.3984 11.5769 12.4244 12.5509C11.4505 13.5249 10.2095 14.1882 8.8586 14.4569C7.50766 14.7256 6.10738 14.5877 4.83482 14.0606C3.56226 13.5335 2.47459 12.6408 1.70934 11.4956C0.944098 10.3503 0.535649 9.00383 0.535649 7.62643C0.533441 5.6314 1.30103 3.71244 2.67851 2.26928"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M0.535645 2.80499L2.6785 2.26928L3.21422 4.41214M7.49993 3.87642V8.16214L10.2856 9.55499"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>{' '}
							Banned Accounts
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Feedback;
