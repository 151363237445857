import { apiTwo } from "./apiSlice";

export const transactionApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query({
      query: () => ({
        url: `admin/getAllTransactions`,
        keepUnusedDataFor: 5,
      }),
    }),
    getP2PTransactions: builder.query({
      query: () => ({
        url: `admin/getP2P`,
        keepUnusedDataFor: 5,
      }),
    }),
    getTransactionsWithTransactionTypensactions: builder.query({
      query: () => ({
        url: `admin/transactionsWithTransactionType`,
        keepUnusedDataFor: 5,
      }),
    }),
    getAllUsersTransactions: builder.query({
      query: () => ({
        url: `admin/getUserExpenseTransactions`,
        keepUnusedDataFor: 5,
      }),
    }),

    getUserCryptoSellTransaction: builder.query({
      query: () => ({
        url: `admin/getUserCryptoSellTransaction`,
        keepUnusedDataFor: 5,
      }),
    }),

    getUserSellGiftcardTransaction: builder.query({
      query: () => ({
        url: `admin/getUserSellGiftcardTransactions`,
        keepUnusedDataFor: 5,
      }),
    }),
    getSuccessFullGiftcardTransaction: builder.query({
      query: () => ({
        url: `admin/getSuccessFullGiftcardTransaction`,
        keepUnusedDataFor: 5,
      }),
    }),
    getSuccessFullCryptoTransaction: builder.query({
      query: () => ({
        url: `admin/getSuccessFullCryptoTransaction`,
        keepUnusedDataFor: 5,
      }),
    }),

    getTransactionsWithCategories: builder.mutation({
      query: (data) => ({
        url: `admin/getTransactionsWithCategories`,
        method: "POST",
        body: data,
      }),
    }),

    setGiftCardRate: builder.mutation({
      query: (data) => ({
        url: `admin/setGiftCardRate/${data.rateId}`,
        method: "PUT",
        body: data,
      }),
    }),
    setCryptoRate: builder.mutation({
      query: (data) => ({
        url: `admin/setCryptoRate/${data.id}`,
        method: "PUT",
        body: data.form,
      }),
    }),
    getGiftCardTotalTransactionBalance: builder.query({
      query: () => ({
        url: `admin/giftCardTotalTransactionBalance`,
        keepUnusedDataFor: 5,
      }),
    }),
    getCryptoTotalTransactionBalance: builder.query({
      query: () => ({
        url: `admin/cryptoTotalTransactionBalance`,
        keepUnusedDataFor: 5,
      }),
    }),
    getTotalBalanceInSystem: builder.query({
      query: () => ({
        url: `admin/getTotalBalanceInSystem`,
        keepUnusedDataFor: 5,
      }),
    }),
    getTotalUsersBalance: builder.query({
      query: () => ({
        url: `admin/getTotalUsersBalance`,
        keepUnusedDataFor: 5,
      }),
    }),
    fetchCryptoNetworkType: builder.query({
      query: () => ({
        url: `admin/fetchCryptoNetworkType`,
        keepUnusedDataFor: 5,
      }),
    }),
  }),
});

export const {
  useGetAllUsersTransactionsQuery,
  useGetUserCryptoSellTransactionQuery,
  useGetUserSellGiftcardTransactionQuery,
  useGetAllTransactionsQuery,
  useGetTransactionsWithTransactionTypensactionsQuery,
  useSetCryptoRateMutation,
  useSetGiftCardRateMutation,
  useGetP2PTransactionsQuery,
  useGetCryptoTotalTransactionBalanceQuery,
  useGetGiftCardTotalTransactionBalanceQuery,
  useGetTotalBalanceInSystemQuery,
  useGetTotalUsersBalanceQuery,
  useFetchCryptoNetworkTypeQuery,
} = transactionApiSlice;
