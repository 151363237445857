import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Spinner from '../../../components/Spinner';
import { useCreateAdminMutation } from '../../../Slices/userApsSlice';

function Index() {
	const [createModal, setCreateModal] = useState(false);
	const [passwordType, setPasswordType] = useState('password');

	const [createAdmin, { isLoading }] = useCreateAdminMutation();
	const { register, handleSubmit } = useForm();
	const createHandler = async (data) => {
		try {
			await createAdmin(data).unwrap();
			toast.success('Create Admin Successful');
		} catch (err) {
			toast.error(err?.data?.msg || err.error);
		}
	};
	return (
    <div className="px-5">
      <div className="flex justify-between gap-x-2  my-10 ">
        <div className="">
          <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
            Home{" "}
            <span class=" text-gray-500 dark:text-gray-300 rtl:-scale-x-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>{" "}
            Customer Support Centre
          </h2>
        </div>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-4 my-10">
        <div className="sm:col-span-9">
          <div className="flex justify-between items-center  gap-x-2  mb-5 ">
            <div className="">
              <h2 className="text-[#0C0C0C] font-extrabold text-xl py-1 text-left  block ">
                Customer Support Centre
              </h2>
              <h2 className="text-[#4E4E4E] text-sm font-light text-left flex">
                Select which notifications you would like to receive
              </h2>
            </div>
          </div>

          <section class="container   rounded mx-auto">
            <div class="flex flex-col">
              <div class=" overflow-x-auto scrollbar-hide ">
                <div class="inline-block min-w-full py-2 align-middle ">
                  <div class="overflow-hidden ">
                    <div class="min-w-full ">
                      <Link
                        to="reports"
                        className="my-2 border-b flex items-center justify-between p-5"
                      >
                        <div className="">
                          <h2 className="text-[#0C0C0C] font-extrabold text-lg py-1 text-left  block ">
                            Report
                          </h2>
                          <h2 className="text-[#4E4E4E] text-xs font-light text-left flex">
                            see all customers report concerning issues
                            experienced on the Platform
                          </h2>
                        </div>

                        <div class="px-3 text-sm text-[#fff] whitespace-nowrap ">
                          <div className=" px-3 rounded-lg">
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1L6 6L1 11"
                                stroke="black"
                                stroke-width="2"
                              />
                            </svg>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="messages"
                        className="my-2 border-b flex items-center justify-between p-5 "
                      >
                        <div className="">
                          <h2 className="text-[#0C0C0C] font-extrabold text-lg py-1 text-left  block ">
                            Message (Emails)
                          </h2>
                          <h2 className="text-[#4E4E4E] text-xs font-light text-left flex">
                            Send of Emails to the Users in general for news
                            update and also app Update.
                          </h2>
                        </div>

                        <div class="px-3 text-sm text-[#fff] whitespace-nowrap ">
                          <div className=" px-3 rounded-lg">
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1L6 6L1 11"
                                stroke="black"
                                stroke-width="2"
                              />
                            </svg>
                          </div>
                        </div>
                      </Link>
                      <Link
                        to="feedbacks"
                        className="my-2 border-b flex items-center justify-between p-5"
                      >
                        <div className="">
                          <h2 className="text-[#0C0C0C] font-extrabold text-lg py-1 text-left  block ">
                            Users Feedback
                          </h2>
                          <h2 className="text-[#4E4E4E] text-xs font-light text-left flex">
                            testimonials of service that we're rendering to
                            them.
                          </h2>
                        </div>

                        <div class="px-3 text-sm text-[#fff] whitespace-nowrap ">
                          <div className=" px-3 rounded-lg">
                            <svg
                              width="8"
                              height="12"
                              viewBox="0 0 8 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1L6 6L1 11"
                                stroke="black"
                                stroke-width="2"
                              />
                            </svg>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="sm:col-span-3">
          <div className=" my-3 p-3 bg-gradient-to-b from-[#284C84]  to-[#062F70] rounded-md  ">
            <h2 className="text-[#fff] font-semibold text-base px-4 py-2 text-left  block ">
              Quick Access
            </h2>
            <Link
              to="/dashboard/users"
              className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_266_4334)">
                  <path
                    d="M5.33366 7.33333C5.15685 7.33333 4.98728 7.40357 4.86225 7.52859C4.73723 7.65361 4.66699 7.82318 4.66699 7.99999C4.66699 8.17681 4.73723 8.34637 4.86225 8.4714C4.98728 8.59642 5.15685 8.66666 5.33366 8.66666H10.667C10.8438 8.66666 11.0134 8.59642 11.1384 8.4714C11.2634 8.34637 11.3337 8.17681 11.3337 7.99999C11.3337 7.82318 11.2634 7.65361 11.1384 7.52859C11.0134 7.40357 10.8438 7.33333 10.667 7.33333H5.33366Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.3337 7.99999C15.3337 12.05 12.0503 15.3333 8.00033 15.3333C3.95033 15.3333 0.666992 12.05 0.666992 7.99999C0.666992 3.94999 3.95033 0.666656 8.00033 0.666656C12.0503 0.666656 15.3337 3.94999 15.3337 7.99999ZM14.0003 7.99999C14.0003 8.78792 13.8451 9.56814 13.5436 10.2961C13.2421 11.024 12.8001 11.6855 12.243 12.2426C11.6858 12.7998 11.0244 13.2417 10.2964 13.5433C9.56847 13.8448 8.78826 14 8.00033 14C7.21239 14 6.43218 13.8448 5.70423 13.5433C4.97627 13.2417 4.31484 12.7998 3.75768 12.2426C3.20053 11.6855 2.75858 11.024 2.45705 10.2961C2.15552 9.56814 2.00033 8.78792 2.00033 7.99999C2.00033 6.40869 2.63247 4.88257 3.75768 3.75735C4.8829 2.63213 6.40903 1.99999 8.00033 1.99999C9.59162 1.99999 11.1177 2.63213 12.243 3.75735C13.3682 4.88257 14.0003 6.40869 14.0003 7.99999Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_266_4334">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Ban Admin
            </Link>
            <button
              onClick={() => {
                setCreateModal(true);
              }}
              className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.13086 10.5C6.13086 10.3259 6.2 10.159 6.32307 10.036C6.44614 9.91288 6.61306 9.84374 6.78711 9.84374H9.84348V6.78737C9.84348 6.61332 9.91263 6.4464 10.0357 6.32333C10.1588 6.20026 10.3257 6.13112 10.4997 6.13112C10.6738 6.13112 10.8407 6.20026 10.9638 6.32333C11.0868 6.4464 11.156 6.61332 11.156 6.78737V9.84374H14.2124C14.3864 9.84374 14.5533 9.91288 14.6764 10.036C14.7995 10.159 14.8686 10.3259 14.8686 10.5C14.8686 10.674 14.7995 10.841 14.6764 10.964C14.5533 11.0871 14.3864 11.1562 14.2124 11.1562H11.156V14.2126C11.156 14.3867 11.0868 14.5536 10.9638 14.6767C10.8407 14.7997 10.6738 14.8689 10.4997 14.8689C10.3257 14.8689 10.1588 14.7997 10.0357 14.6767C9.91263 14.5536 9.84348 14.3867 9.84348 14.2126V11.1562H6.78711C6.61306 11.1562 6.44614 11.0871 6.32307 10.964C6.2 10.841 6.13086 10.674 6.13086 10.5Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.40279 3.29788C9.12619 2.99596 11.8746 2.99596 14.598 3.29788C16.1967 3.47638 17.4873 4.7355 17.6745 6.34288C17.9983 9.10525 17.9983 11.8956 17.6745 14.658C17.4864 16.2654 16.1958 17.5236 14.598 17.703C11.8746 18.0049 9.12619 18.0049 6.40279 17.703C4.80416 17.5236 3.51354 16.2654 3.32629 14.658C3.00322 11.8957 3.00322 9.10517 3.32629 6.34288C3.51354 4.7355 4.80504 3.47638 6.40279 3.29788ZM14.4528 4.60163C11.8259 4.31046 9.17491 4.31046 6.54804 4.60163C6.06175 4.65557 5.60785 4.87189 5.25967 5.21563C4.91148 5.55936 4.68935 6.01045 4.62916 6.496C4.31797 9.15685 4.31797 11.8449 4.62916 14.5058C4.68954 14.9911 4.91175 15.442 5.25991 15.7856C5.60808 16.1292 6.06188 16.3453 6.54804 16.3993C9.15291 16.6898 11.8479 16.6898 14.4528 16.3993C14.9388 16.3452 15.3924 16.1289 15.7404 15.7853C16.0884 15.4418 16.3104 14.991 16.3708 14.5058C16.682 11.8449 16.682 9.15685 16.3708 6.496C16.3103 6.01089 16.0881 5.56032 15.7401 5.21695C15.3922 4.87357 14.9387 4.65743 14.4528 4.60338V4.60163Z"
                  fill="white"
                />
              </svg>
              Add Admin Account
            </button>
            <button className="flex gap-x-2 w-full text-left block px-4 py-2 text-xs text-[#fff] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8125 3.89583L13.3735 10.9969C13.2613 12.8112 13.2053 13.7184 12.7506 14.3706C12.5257 14.693 12.2362 14.9652 11.9005 15.1697C11.2215 15.5833 10.3126 15.5833 8.49483 15.5833C6.67471 15.5833 5.76463 15.5833 5.08516 15.1689C4.74923 14.964 4.45967 14.6914 4.2349 14.3684C3.78029 13.7152 3.72544 12.8067 3.61577 10.9899L3.1875 3.89583"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M14.875 3.89583H2.125"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M11.3737 3.89584L10.8902 2.89831C10.5689 2.23569 10.4083 1.90437 10.1313 1.69775C10.0698 1.65191 10.0048 1.61114 9.93674 1.57583C9.62996 1.41667 9.26177 1.41667 8.52538 1.41667C7.77051 1.41667 7.39304 1.41667 7.0812 1.58251C7.01207 1.61926 6.94612 1.66168 6.88401 1.70933C6.60375 1.92433 6.4472 2.26777 6.1341 2.95465L5.70508 3.89584"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M6.72949 11.6875V7.4375"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M10.2705 11.6875V7.4375"
                  stroke="white"
                  stroke-linecap="round"
                />
              </svg>
              Remove Admin Account
            </button>
          </div>
        </div>
      </div>

      {createModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-end">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setCreateModal(false);
                  }}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm  ml-auto inline-flex items-center "
                >
                  <svg
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>

              <h3 className="text-[#000] text-lg font-semibold py-3">
                Create New Admin Account
              </h3>

              <form onSubmit={handleSubmit(createHandler)}>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Full Name *
                  </label>
                  <input
                    type="text"
                    name="Name"
                    required
                    {...register("name")}
                    placeholder="Full Name"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div>
                  <label
                    for="username"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Email *
                  </label>
                  <input
                    type="email"
                    name="email"
                    required
                    {...register("email")}
                    placeholder="Email Address"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div>
                  <label
                    for="role"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Role *
                  </label>
                  <input
                    type="text"
                    name="role"
                    required
                    {...register("role")}
                    value="Admin"
                    className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>
                <div className="mt-2">
                  <label
                    for="password"
                    className="text-left block text-sm text-gray-800 font-semibold py-2"
                  >
                    Password *
                  </label>
                  <div className="flex ">
                    <input
                      {...register("password")}
                      required
                      type={passwordType}
                      name="password"
                      id="password"
                      placeholder="Your Password"
                      className="block w-full px-4 py-4  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                      <button
                        type="button"
                        onClick={() => {
                          if (passwordType === "password") {
                            setPasswordType("text");
                            return;
                          }
                          setPasswordType("password");
                        }}
                        className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                      >
                        {passwordType === "password" ? (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_5982_274025)">
                              <path
                                d="M17.94 17.94C16.2306 19.243 14.1491 19.9649 12 20C5 20 1 12 1 12C2.24389 9.68192 3.96914 7.65663 6.06 6.06003M9.9 4.24002C10.5883 4.0789 11.2931 3.99836 12 4.00003C19 4.00003 23 12 23 12C22.393 13.1356 21.6691 14.2048 20.84 15.19M14.12 14.12C13.8454 14.4148 13.5141 14.6512 13.1462 14.8151C12.7782 14.9791 12.3809 15.0673 11.9781 15.0744C11.5753 15.0815 11.1752 15.0074 10.8016 14.8565C10.4281 14.7056 10.0887 14.4811 9.80385 14.1962C9.51897 13.9113 9.29439 13.572 9.14351 13.1984C8.99262 12.8249 8.91853 12.4247 8.92563 12.0219C8.93274 11.6191 9.02091 11.2219 9.18488 10.8539C9.34884 10.4859 9.58525 10.1547 9.88 9.88003"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M1 1L23 23"
                                stroke="#757E7E"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_5982_274025">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                              fill="#757E7E"
                            />
                          </svg>
                        )}
                      </button>
                    </span>
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full px-4 py-3 tracking-wide text-white transition-colors duration-300 transform bg-[#284C84] rounded-sm hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                  >
                    {isLoading ? <Spinner /> : "Create New Admin Account"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Index;
