import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  useBanUserMutation,
  useDeleteAdminMutation,
} from "../Slices/userApsSlice";
import { BASE_URL } from "../utils";
import Spinner from "./Spinner";
import { useForm } from "react-hook-form";

const AdminPrivilegesForm = ({ items, setReFetch }) => {
  const { register, handleSubmit, setValue } = useForm();
  useEffect(() => {
    if (items) {
      setValue("can_approve_gift_cards", items.can_approve_gift_cards);
      setValue("can_approve_cryptos", items.can_approve_cryptos);
    }
  }, [items, setValue]);
  // const [can_approve_gift_cards, setCan_approve_gift_cards] = useState(false);
  // const [can_approve_cryptos, setCan_approve_cryptos] = useState(false);
  const [giftCardsenabled, setGiftCardsenabled] = useState(false);
  const [cryptosenabled, setCryptosenabled] = useState(false);
  const [banModal, setBanModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unbanModal, setUnbanModal] = useState(false);
  const [deleteSucess, setDeleteSucess] = useState(false);
  const [banSucess, setBanSucess] = useState(false);
  const [unbanSucess, setUnbanSucess] = useState(false);

  const [banuser] = useBanUserMutation();
  const banHandler = async (bandata) => {
    try {
      await banuser(bandata);
      setBanModal(false);
      setBanSucess(true);
      setReFetch(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const unbanHandler = async (bandata) => {
    try {
      await banuser(bandata);
      setUnbanModal(false);
      setUnbanSucess(true);
      setReFetch(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [deleteadmin] = useDeleteAdminMutation();
  const deleteHandler = async (deletedata) => {
    try {
      const res = await deleteadmin(deletedata);
      setDeleteModal(false);
      setDeleteSucess(true);
      toast.success(res.message);
      setReFetch(true);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const { userInfo } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  async function submitHandler(data) {
    setLoading(true);
    try {
      await axios.put(
        `${BASE_URL}admin/updatePrivilege/${items.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      );
      toast.success("Profile updated successfully");
      setLoading(false);
      setReFetch(true);
    } catch (err) {
      setLoading(false);
      toast.error(err?.data?.message || err.message || err.error.message);
    }
  }

  return (
    <div>
      <div className="grid grid-cols-12  border-t  py-1 px-20">
        <div className="col-span-6">
          <div class=" py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
            Account Type
          </div>
          {items.role === "superAdmin" && (
            <div class="flex items-center mb-4">
              <input
                id="country-option-1"
                type="radio"
                checked
                class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 checked:bg-[#062F70]"
              />
              <label
                for="country-option-1"
                class="text-sm font-medium text-gray-900 ml-2 block"
              >
                Super Admin
              </label>
            </div>
          )}
          {items.role === "Admin" && (
            <div class="flex items-center mb-4">
              <input
                id="country-option-2"
                type="radio"
                checked
                class="h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300 checked:bg-[#062F70]"
              />
              <label
                for="country-option-2"
                class="text-sm font-medium text-gray-900 ml-2 block"
              >
                Admin
              </label>
            </div>
          )}

          <div className="w-[8rem] space-y-3 mt-12">
            <button
              onClick={(e) => {
                e.preventDefault();
                setDeleteModal(true);
              }}
              className="w-full flex items-center gap-x-1 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-3 rounded-lg "
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.4375 4.35413L14.9469 12.2907C14.8215 14.3184 14.7589 15.3322 14.2506 16.0612C13.9993 16.4216 13.6758 16.7257 13.3006 16.9543C12.5417 17.4166 11.5259 17.4166 9.49422 17.4166C7.45997 17.4166 6.44282 17.4166 5.68341 16.9534C5.30797 16.7245 4.98433 16.4198 4.73312 16.0588C4.22503 15.3287 4.16373 14.3134 4.04115 12.2828L3.5625 4.35413"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M16.625 4.35413H2.375"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M12.712 4.35421L12.1716 3.23933C11.8125 2.49875 11.6331 2.12845 11.3234 1.89752C11.2547 1.84629 11.182 1.80072 11.106 1.76126C10.7631 1.58337 10.3516 1.58337 9.52857 1.58337C8.68489 1.58337 8.26301 1.58337 7.91448 1.76872C7.83722 1.8098 7.76351 1.85721 7.69409 1.91047C7.38086 2.15076 7.20589 2.5346 6.85596 3.30229L6.37646 4.35421"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M7.521 13.0625V8.3125"
                  stroke="white"
                  stroke-linecap="round"
                />
                <path
                  d="M11.479 13.0625V8.3125"
                  stroke="white"
                  stroke-linecap="round"
                />
              </svg>
              Delete User
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setBanModal(true);
              }}
              className="w-full flex items-center gap-x-1 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#D3B61F]  px-3 py-3 rounded-lg "
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.50016 1.58337C13.8543 1.58337 17.4168 5.14587 17.4168 9.50004C17.4168 13.8542 13.8543 17.4167 9.50016 17.4167C5.146 17.4167 1.5835 13.8542 1.5835 9.50004C1.5835 5.14587 5.146 1.58337 9.50016 1.58337ZM9.50016 3.16671C7.996 3.16671 6.65016 3.64171 5.621 4.51254L14.4877 13.3792C15.2793 12.2709 15.8335 10.925 15.8335 9.50004C15.8335 6.01671 12.9835 3.16671 9.50016 3.16671ZM13.3793 14.4875L4.51266 5.62087C3.64183 6.65004 3.16683 7.99587 3.16683 9.50004C3.16683 12.9834 6.01683 15.8334 9.50016 15.8334C11.0043 15.8334 12.3502 15.3584 13.3793 14.4875Z"
                  fill="white"
                />
              </svg>
              Ban User
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit(submitHandler)} className="col-span-6">
          <div class=" py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
            Admin Privileges
          </div>
          <div className="flex my-3">
            <div className="relative flex flex-col items-center justify-center overflow-hidden">
              <div className="flex">
                <label class="inline-flex relative items-center mr-3 cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={cryptosenabled}
                    {...register("can_approve_cryptos")}
                  />

                  <div
                    onClick={() => {
                      setCryptosenabled(!cryptosenabled);
                    }}
                    className="w-11 h-6 border border-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:bg-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-gray-300 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#062F70]"
                  ></div>
                </label>
              </div>
            </div>

            <div class="  text-sm font-light text-[#000]">
              Approve Crypto Trade Payments
            </div>
          </div>
          <div className="flex my-3">
            <div className="relative flex flex-col items-center justify-center overflow-hidden">
              <div className="flex">
                <label class="inline-flex relative items-center mr-3 cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={giftCardsenabled}
                    {...register("can_approve_cryptos")}
                  />
                  <div
                    onClick={() => {
                      setGiftCardsenabled(!giftCardsenabled);
                    }}
                    className="w-11 h-6 border border-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:bg-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-gray-300 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#062F70]"
                  ></div>
                </label>
              </div>
            </div>

            <div class="  text-sm font-light text-[#000]">
              Approve Gift Card Payments
            </div>
          </div>
          <button
            type="submit"
            value="submit"
            className="flex items-center gap-x-1 transition-colors duration-200 text-xs hover:text-gray-300 focus:outline-none text-[#fff] bg-[#6B7280]  px-3 py-3 rounded-lg "
          >
            {loading ? <Spinner /> : "Update Privileges"}
          </button>
        </form>
      </div>
      {banModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Account Ban
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to ban this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setBanModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const user_id = items.id;
                    const banUser = "true";
                    const data = { user_id, banUser };
                    banHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {banSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Account Banned Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Banned this user
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setBanSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {unbanModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Unban Account
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to Unban this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUnbanModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const user_id = items.id;
                    const banUser = "false";
                    const data = { user_id, banUser };
                    unbanHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0AB02E]  px-3 py-4 rounded-lg "
                >
                  Yes, Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {unbanSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          B
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Account Unbanned Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully unbanned this user.
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUnbanSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Confirm Account Delete
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#141414]/70">
                Are you sure you want to delete this user ?
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteModal(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#0F3776] bg-[#D6DDE8]  px-3 py-4 rounded-lg "
                >
                  No, Cancel
                </button>
                <button
                  onClick={() => {
                    const data = items.id;
                    deleteHandler(data);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#CA0D0D]  px-3 py-4 rounded-lg "
                >
                  Yes, Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteSucess && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-xl font-semibold dark:text-white">
                  Account Deleted Successfully
                </h3>
              </div>

              <p className="text-left py-5 text-sm text-[#D3B61F]">
                You have successfully Deleted this user
              </p>
              <div className="flex gap-x-3 items-start justify-between">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteSucess(false);
                  }}
                  className="font-semibold w-full transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#0F3776]  px-3 py-4 rounded-lg "
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPrivilegesForm;
